/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../styles';
import UserTree from '../../common/userTreeNew';
import { useUpdate } from '../../../containers/customerMain';
import store from '../../../store';
import { USE_AQCUISITION_TREE_GROUP } from '../../../constants';
import { RESPONSE_TYPE_ACQUISITION } from '../../../constants/responseType';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 187,
    background: '#fff',
    border: '1px solid #C8C8C8',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
  },
  title: {
    whiteSpace: 'nowrap',
    textAlign: 'left',
    borderRadius: 0,
    paddingLeft: 8,
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '900',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  button: {
    background: '#fff',
    width: '100%',
    textAlign: 'left',
    borderRadius: 0,
    padding: 0,
    '& > span': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: '3px 0px',
    },
    '&:hover .MuiButton-label': { backgroundColor: `${theme.palette.primaryColor}29` },
    '&.Mui-disabled': {
      color: '#000',
    },
  },
}));

function CustomerAcquisition(props) {
  const { input } = props;
  const { original, update } = useUpdate(input.customer);

  const { responseHeader } = store.getState();

  const classes = useStyles();
  const baseClasses = commonStyles();

  // ツリー内で使われる（顧客メイン上には見せない）データ
  const [selectUser, setSelectUser] = useState({
    userId: original.acquisitionId || responseHeader.userId,
    userName: original.acquisitionName || responseHeader.userName,
  });
  const [selectDivision, setSelectDivision] = useState({});
  const [displayNameInTree, setDisplayNameInTree] = useState(original.acquisitionMemo);
  const [initialAcquirer, setInitialAcquirer] = useState(String(original.acquisitionCode));

  // 獲得者がツリーから選択できるか
  const isTree = USE_AQCUISITION_TREE_GROUP.includes(Number(initialAcquirer));

  // 顧客メイン上の表示
  const [label, setLabel] = useState(isTree ? original.acquisitionName : original.acquisitionMemo);
  // ツリーの初期選択を保持するために使用
  const tmpUserRef = useRef({
    userId: selectUser.userId,
    userName: selectUser.userName,
  });

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    tmpUserRef.current = ({
      userId: selectUser.userId,
      userName: selectUser.userName,
    });
    setOpen(true);
  };

  const handleSelectFunction = () => {
    // 獲得者を更新する
    update({
      acquisitionUserId: isTree
        ? selectUser.userId
        : null,
      acquisitionCode: Number(initialAcquirer),
      acquisitionMemo: !isTree
        ? displayNameInTree
        : null,
    });
    setLabel(isTree ? selectUser.userName : displayNameInTree);
  };

  const handleClearFunction = () => {
    // 獲得者の表示を初期化
    setDisplayNameInTree(original.acquisitionMemo);
    setInitialAcquirer(String(original.acquisitionCode));
    setSelectUser(tmpUserRef.current);
  };

  return (
    <Grid className={classes.root}>
      <Button
        className={classes.button}
        onClick={handleOpen}
        disabled={!RESPONSE_TYPE_ACQUISITION.includes(original.largeResponseTypeId)}
      >
        <Typography className={`${baseClasses.title6} ${classes.title}`}>
          源泉獲得者
        </Typography>
        <Typography className={classes.txt}>
          {label}
        </Typography>
      </Button>
      <UserTree
        selectUser={selectUser}
        setSelectUser={setSelectUser}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        isOpenTree={open}
        setIsOpenTree={setOpen}
        handleSelectFunction={handleSelectFunction}
        handleClearFunction={handleClearFunction}
        isDialog
        isSelectUserWindow
        isAcquirer
        initialAcquirer={initialAcquirer}
        setInitialAcquirer={setInitialAcquirer}
        setDisplayName={setDisplayNameInTree}
        defaultDisplayName={displayNameInTree}
        isFromCustomerMain
      />
    </Grid>
  );
}

export default React.memo(CustomerAcquisition);
