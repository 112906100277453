import { useEffect } from 'react';
import {
  Grid,
  TablePagination,
  makeStyles,
} from '@material-ui/core';
import { useCustomerMain } from '../../containers/customerMain/customerMainContext';
import CustomerMainHeader from './customerMainHeader';
import commonTheme from '../styles/theme';

const useStyles = makeStyles({
  root: {
    [commonTheme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '16px 0',
    },
    [commonTheme.breakpoints.up('md')]: {
      height: 60,
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #ccc',
    },
    '& .MuiGrid-root': {
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    '& .MuiTablePagination-root': {
      '& .MuiToolbar-root': {
        [commonTheme.breakpoints.up('md')]: {
          padding: 0,
        },
      },
      '& .MuiTablePagination-selectRoot': {
        [commonTheme.breakpoints.up('md')]: {
          marginLeft: 0,
          marginRight: 40,
        },
      },
      '& .MuiTablePagination-actions': {
        [commonTheme.breakpoints.up('md')]: {
          marginLeft: 14,
        },
        '& button': {
          [commonTheme.breakpoints.up('md')]: {
            padding: 6,
          },
        },
      },
      '& .MuiTablePagination-spacer + .MuiTablePagination-caption': {
        [commonTheme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
    },
  },
});

export default function Header(props) {
  const { isSp } = props;
  const {
    functions,
    selectCustomer,
    page,
    rowsPerPage,
    isDisabled,
    setIsDisabled,
  } = useCustomerMain();
  const classes = useStyles();

  // 顧客情報を取得
  useEffect(() => {
    if (selectCustomer.length > 0) {
      functions.updatePage(0);
    } else {
      functions.initialCustomerFunction();
    }
  }, [selectCustomer]);

  const onPageChange = (event, newPage) => {
    setIsDisabled(true);
    functions.handleChangePage(event, newPage);
  };

  return (
    <Grid className={classes.root}>
      <CustomerMainHeader
        updatePage={functions.updatePage}
        isSp={isSp}
        isDisabled={isDisabled}
      />
      {isSp ? null : (
        <TablePagination
          rowsPerPageOptions={[20, 40, 60]}
          component="div"
          count={selectCustomer.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={functions.handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
          SelectProps={{
            disabled: isDisabled,
          }}
          backIconButtonProps={
            isDisabled
              ? {
                disabled: isDisabled,
              }
              : undefined
          }
          nextIconButtonProps={
            isDisabled
              ? {
                disabled: isDisabled,
              }
              : undefined
          }
        />
      )}
    </Grid>
  );
}
