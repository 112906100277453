import { createSlice } from '@reduxjs/toolkit';
import {
  getSessionStorage,
  setDailyStorage,
} from '../../commonFunction';
import { URL_MAPPER } from '../../constants/urls';
import * as LS from '../../constants/sessionStorage';

// ローカルストレージに保存しない項目
// 原則各画面単位で特別に与える検索条件の初期値と、その画面特有の検索条件を保存しない方針

// 一斉メール
const allMailIgnoreSet = new Set([
  'emailStatusCodes',
  'isSendableMailExist',
  'isWebSearchOption',
]);
const isAllMailIgnore = (key) => {
  if (!window.location.href.match(URL_MAPPER.mail)) return false;
  return allMailIgnoreSet.has(key);
};

// 見込み案件管理表
const prospectsListIgnoreSet = new Set([
  'guidanceCountMin',
  'guidanceCountMax',
  'userLevel',
  'searchType2',
  'searchType3',
  'searchType4',
  'searchType5',
  'isContactOrReceptionSearch',
]);
const isProspectsListIgnore = (key) => {
  if (!window.location.href.match(URL_MAPPER.customer_list)) return false;
  return prospectsListIgnoreSet.has(key);
};

export const createInitialState = () => ({
  // typeCode: '',
  // unitCode: '',
  level: getSessionStorage(LS.TREE_LEVEL, ''),
  // divisionId: getSessionStorage(LS.TREE_DIVISION_ID, ''),
  userLevelMin: getSessionStorage(LS.TREE_USERLEVELMIN, ''), // 重要情報 - ランク
  userLevelMax: getSessionStorage(LS.TREE_USERLEVELMAX, ''), // 重要情報 - ランク
  isWebHistoryExist: getSessionStorage(LS.TREE_ISWEBHISTORYEXIST, ''), // 重要情報 - Web履歴あり
  isSendableMailExist: getSessionStorage(LS.TREE_ISSENDABLEMAILEXIST, ''), // 重要情報 - Mailあり
  isCallablePhoneNumberExist: getSessionStorage(LS.TREE_ISCALLABLEPHONENUMBEREXIST, ''), // 重要情報 - TELあり
  isDirectExist: getSessionStorage(LS.TREE_ISDIRECTEXIST, ''), // 重要情報 - 指示あり
  customerStatusCode: getSessionStorage(LS.TREE_CUSTOMERSTATUSCODE, 1), // 重要情報 - 追跡状況
  isReceiveShare: getSessionStorage(LS.TREE_ISRECEIVESHARE, ''), // 重要情報 - 共有された顧客のみ
  isSendShare: getSessionStorage(LS.TREE_ISSENDSHARE, ''), // 重要情報 - 共有してる顧客のみ
  actionFrom: getSessionStorage(LS.TREE_ACTIONFROM, ''), // 対応履歴 - 対応日時From
  actionTo: getSessionStorage(LS.TREE_ACTIONTO, ''), // 対応履歴 - 対応日時To
  actions: getSessionStorage(LS.TREE_ACTIONS, ''), // 対応履歴 - 種類/反応 親グループのID、種類/反応 被選択複数子要素のオブジェクト配列
  // eslint-disable-next-line max-len
  actionKeyWord: getSessionStorage(LS.TREE_ACTIONKEYWORD, ''), // 対応履歴 - キーワード
  actionSearchCode: getSessionStorage(LS.TREE_ACTIONSEARCHCODE, []), // 対応履歴 - キーワード キーワードの検索の範囲
  responseFrom: getSessionStorage(LS.TREE_RESPONSEFROM, ''), // 反響情報 - 反響日From
  responseTo: getSessionStorage(LS.TREE_RESPONSETO, ''), // 反響情報 - 反響日To
  effectFrom: getSessionStorage(LS.TREE_EFFECTFROM, ''), // 反響情報 - 反響基準日From
  effectTo: getSessionStorage(LS.TREE_EFFECTTO, ''), // 反響情報 - 反響基準日To
  responseTypeList: getSessionStorage(LS.TREE_RESPONSETYPELIST, []), // 反響情報 - 媒体 表示用
  largeResponseTypeId: getSessionStorage(LS.TREE_LARGERESPONSETYPEID, ''), // 反響情報 - 媒体（大）
  mediumResponseTypeId: getSessionStorage(LS.TREE_MEDIUMRESPONSETYPEID, ''), // 反響情報 - 媒体（中）
  smallResponseTypeId: getSessionStorage(LS.TREE_SMALLRESPONSETYPEID, ''), // 反響情報 - 媒体（小）
  fineResponseTypeId: getSessionStorage(LS.TREE_FINERESPONSETYPEID, ''), // 反響情報 - 媒体（細）
  memberStatusCode: getSessionStorage(LS.TREE_MEMBERSTATUSCODE, ''), // 反響情報 - 会員状態
  houseBudgetMin: getSessionStorage(LS.TREE_HOUSEBUDGETMIN, ''), // 希望条件 - 戸建価格Min
  houseBudgetMax: getSessionStorage(LS.TREE_HOUSEBUDGETMAX, ''), // 希望条件 - 戸建価格Max
  livingSpaceMin: getSessionStorage(LS.TREE_LIVINGSPACEMIN, ''), // 希望条件 - 戸建広さMin
  livingSpaceMax: getSessionStorage(LS.TREE_LIVINGSPACEMAX, ''), // 希望条件 - 戸建広さMax
  landBudgetMin: getSessionStorage(LS.TREE_LANDBUDGETMIN, ''), // 希望条件 - 土地価格Min
  landBudgetMax: getSessionStorage(LS.TREE_LANDBUDGETMAX, ''), // 希望条件 - 土地価格Max
  wishLandAreaMin: getSessionStorage(LS.TREE_WISHLANDAREAMIN, ''), // 希望条件 - 土地広さMin
  wishLandAreaMax: getSessionStorage(LS.TREE_WISHLANDAREAMAX, ''), // 希望条件 - 土地広さMax
  budgetMin: getSessionStorage(LS.TREE_BUDGETMIN, ''), // 詳細検索では使わない
  budgetMax: getSessionStorage(LS.TREE_BUDGETMAX, ''), // 詳細検索では使わない
  floorPlan: getSessionStorage(LS.TREE_FLOORPLAN, []), // 希望条件 - 間取り
  annualIncomeMin: getSessionStorage(LS.TREE_ANNUALINCOMEMIN, ''), // 希望条件 - 年収Min
  annualIncomeMax: getSessionStorage(LS.TREE_ANNUALINCOMEMAX, ''), // 希望条件 - 年収Max
  isThinkSale: getSessionStorage(LS.TREE_ISTHINKSALE, ''), // 希望条件 - 検討 - 売却
  isThinkApartment: getSessionStorage(LS.TREE_ISTHINKAPARTMENT, ''), // 希望条件 - 検討 - マンション
  isThinkWholeHouseInvestment: getSessionStorage(LS.TREE_ISTHINKWHOLEHOUSEINVESTMENT, ''), // 希望条件 - 検討 - 一棟物投資
  isThinkDivisionInvestment: getSessionStorage(LS.TREE_ISTHINKDIVISIONINVESTMENT, ''), // 希望条件 - 検討 - 区分投資
  isThinkRebuilding: getSessionStorage(LS.TREE_ISTHINKREBUILDING, ''), // 希望条件 - 検討 - 建替
  wishArea: getSessionStorage(LS.TREE_WISHAREA, ''), // 希望条件 - 地域
  wishAreaCode: getSessionStorage(LS.TREE_WISHAREACODE, []), // 希望条件 - 地域コード
  wishAreaIds: getSessionStorage(LS.TREE_WISHAREAIDS, []), // 希望条件 - 地域ID
  isNoneWishArea: getSessionStorage(LS.TREE_ISNONEWISHAREA, ''), // 希望条件 - 希望地域無し
  // eslint-disable-next-line max-len
  wishAreaSearchTypeCode: getSessionStorage(LS.TREE_WISHAREASEARCHTYPECODE, 0), // 希望条件 - 地域 ～検索に含める(or条件)
  wishRailwayId: getSessionStorage(LS.TREE_WISHRAILWAYID, ''), // 希望条件 沿線ID
  wishRailway: getSessionStorage(LS.TREE_WISHRAILWAY, ''), // 希望条件 沿線名
  // eslint-disable-next-line max-len
  wishRailwayIdSearchTypeCode: getSessionStorage(LS.TREE_WISHRAILWAYIDSEARCHTYPECODE, 0), // 希望条件 沿線検索条件コード ～検索に含める(or条件)
  wishStationFrom: getSessionStorage(LS.TREE_WISHSTATIONFROM, ''), // 希望条件 駅FromId
  wishStationFromName: getSessionStorage(LS.TREE_WISHSTATIONFROMNAME, ''), // 希望条件 駅FromName
  wishStationTo: getSessionStorage(LS.TREE_WISHSTATIONTO, ''), // 希望条件 駅ToID
  wishStationToName: getSessionStorage(LS.TREE_WISHSTATIONTONAME, ''), // 希望条件 駅ToName
  searchStationTypeCode: getSessionStorage(LS.TREE_SEARCHSTATIONTYPECODE, 0),
  // 希望条件 駅 ～検索に含める(and条件)
  mailOpenHistoryCode: getSessionStorage(LS.TREE_MAILOPENHISTORYCODE, ''), // メール開封
  isGuidanceHistorySearch: getSessionStorage(LS.TREE_ISGUIDANCEHISTORYSEARCH, ''), // 行動情報 - 案内実績
  isVisitHistorySearch: getSessionStorage(LS.TREE_ISVISITHISTORYSEARCH, ''), // 行動情報 - 来社実績
  holidayWeekdays: getSessionStorage(LS.TREE_HOLIDAYWEEKDAYS, []), // 補足情報 - お休みの日
  holidayWeekdayOthers: getSessionStorage(LS.TREE_HOLIDAYWEEKDAYOTHERS, ''), // 詳細検索ではいらない
  telStatusCode: getSessionStorage(LS.TREE_TELSTATUSCODE, ''), // 補足情報 - 電話許可
  phoneSupportStatusCode: getSessionStorage(LS.TREE_PHONESUPPORTSTATUSCODE, ''), // 行動情報 電話
  emailStatusCodes: getSessionStorage(LS.TREE_EMAILSTATUSCODES, []), // 補足情報 - メール許可
  mailSendStatusCode: getSessionStorage(LS.TREE_MAILSENDSTATUSCODE, ''), // 詳細検索では使わない
  isAddressExist: getSessionStorage(LS.TREE_ISADDRESSEXIST, ''), //  // 補足情報 - 住所ありなし
  abodeNowBuildingType: getSessionStorage(LS.TREE_ABODENOWBUILDINGTYPE, ''), // 補足情報 - 現況住居種類
  isAllowDm: getSessionStorage(LS.TREE_ISALLOWDM, ''), // 補足情報 - DM許可
  currentUser: getSessionStorage(LS.TREE_CURRENTUSER, ''), // 補足情報 - 現担当Name
  currentUserId: getSessionStorage(LS.TREE_CURRENTUSERID, ''), // 補足情報 - 現担当ID
  formerUser: getSessionStorage(LS.TREE_FORMERUSER, ''), // 補足情報 - 旧担当Name
  formerUserId: getSessionStorage(LS.TREE_FORMERUSERID, ''), // 補足情報 - 旧担当ID
  previousUser: getSessionStorage(LS.TREE_PREVIOUSUSER, ''), // 補足情報 - 前担当Name
  previousUserId: getSessionStorage(LS.TREE_PREVIOUSUSERID, ''), // 補足情報 - 前担当ID
  thinkStartSeasonFrom: getSessionStorage(LS.TREE_THINKSTARTSEASONFROM, ''), // 追わない - 検討再開時期From
  thinkStartSeasonTo: getSessionStorage(LS.TREE_THINKSTARTSEASONTO, ''), // 追わない - 検討再開時期To
  // eslint-disable-next-line max-len
  noContactDetailReasonCode: getSessionStorage(LS.TREE_NOCONTACTDETAILREASONCODES, []), // 追わない - 追わない理由
  noneContactKeyword: getSessionStorage(LS.TREE_NONECONTACTKEYWORD, ''), // 追わない - キーワード
  // eslint-disable-next-line max-len
  noneContactKeywordCodes: getSessionStorage(LS.TREE_NONECONTACTKEYWORDCODES, []), // 追わない - キーワードの検索の範囲
  keyword: getSessionStorage(LS.TREE_KEYWORD, ''), // キーワード - キーワード
  keywordSearch: getSessionStorage(LS.TREE_KEYWORDSEARCH, [1, 2, 3, 4, 5, 6, 7, 8, 9]),
  // キーワードの検索の範囲
  recommendStatus: getSessionStorage(LS.TREE_RECOMMENDSTATUS, ''), // レコメンド - レコメンド状況
  customerIds: getSessionStorage(LS.TREE_CUSTOMERIDS, ''), // 顧客ID - 顧客ID
  convenientTimes: getSessionStorage(LS.TREE_CONVENIENTTIMES, ''), // TELが繋がりやすい時間帯
  lastActionDate: getSessionStorage(LS.TREE_LASTACTIONDATE, ''), // 詳細検索では使わない
  userLevel: getSessionStorage(LS.TREE_USERLEVEL, []), // モバイルのみつかう
  lastAccessDate: getSessionStorage(LS.TREE_LASTACCESSDATE, ''), // 詳細検索では使わない
  displayOrderCode: getSessionStorage(LS.TREE_DISPLAYORDERCODE, ''),
  searchType2: getSessionStorage(LS.TREE_SEARCHTYPE2, ''), // 詳細検索では使わない
  searchType3: getSessionStorage(LS.TREE_SEARCHTYPE3, ''), // 詳細検索では使わない
  searchType4: getSessionStorage(LS.TREE_SEARCHTYPE4, ''), // 直近1週間の案内顧客
  searchType5: getSessionStorage(LS.TREE_SEARCHTYPE5, ''), // 未接触顧客
  guidanceCountMin: getSessionStorage(LS.TREE_GUIDANCECOUNTMIN, ''), // 案内回数 最小
  guidanceCountMax: getSessionStorage(LS.TREE_GUIDANCECOUNTMAX, ''), // 案内回数 最大
  isContactOrReceptionSearch: getSessionStorage(LS.TREE_ISCONTACTORRECEPTIONSEARCH, ''), // 接触/受信あり顧客検索フラグ
  isWebSearchOption: getSessionStorage(LS.TREE_ISWEBSEARCHOPTION, ''), // 反響情報 - WEB動向検索オプション
  isReload: false, // 検索条件を同じ状態でリロードさせる
  isCanCallNow: getSessionStorage(LS.TREE_ISCANCALLNOW, ''), // 今TELが繋がりやすい顧客
  isEffectWeek: getSessionStorage(LS.TREE_ISEFFECTWEEK, ''), // 直近1週間の反響
  isCalledAndNotMailed: getSessionStorage(LS.TREE_ISCALLEDANDNOTMAILED, ''), // TEL済/未Mail
  acquisitions: getSessionStorage(LS.TREE_ACQUISITIONS, {}),
  acquisitionName: getSessionStorage(LS.TREE_ACQUISITIONNAME, ''),
});

export const searchItemsSlice = createSlice({
  name: 'searchItems',
  initialState: {
    searchParams: createInitialState(),
    isDetail: false,
  },
  reducers: {
    // 検索系からのUpdate
    updateSearchItems: (state, data) => {
      state.searchParams = data.payload;
      for (const key in data.payload) {
        if (!isAllMailIgnore(key) && !isProspectsListIgnore(key)) {
          setDailyStorage(key, data.payload[key]);
        }
      }
    },
    // 検索系以外からのUpdate
    updateObjSearchItems: (state, obj) => {
      for (const key in obj.payload) {
        state.searchParams[key] = obj.payload[key];
      }
    },
    // 詳細検索からの更新かどうか
    setIsDetailSearch: (state, data) => {
      state.isDetail = data.payload;
    },
    reload: (state) => {
      const { searchParams } = state;
      state.searchParams = { ...searchParams, isReload: !searchParams.isReload };
    },
    setInitialState: (state) => {
      state.searchParams = createInitialState();
    },
  },
});

export const {
  updateSearchItems,
  updateObjSearchItems,
  setIsDetailSearch,
  reload,
  setInitialState,
} = searchItemsSlice.actions;

export default searchItemsSlice.reducer;
