import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import {
  ACQUISITION_CODE,
  MEMBER_STATUS_CODE,
} from '../../../../constants';

const useStyles = makeStyles({
  root: {
    marginBottom: 8,
    [commonTheme.breakpoints.up('md')]: {
      padding: 8,
    },
  },
  ttl: {
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  response: {
    width: '100%',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 8px 0 32px',
    },
    [commonTheme.breakpoints.up('md')]: {
      border: '1px solid #C8C8C8',
      padding: 8,
      marginTop: 8,
    },
  },
  responseBox: {
    '&:not(:last-child)': {
      [commonTheme.breakpoints.up('md')]: {
        marginBottom: 8,
      },
    },
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  responseBlock: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      padding: '16px 0',
    },
  },
  responseBlock1: {
    [commonTheme.breakpoints.up('md')]: {
      width: 632,
    },
  },
  responseBlock2: {
    [commonTheme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  responseBlock3: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
    [commonTheme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  responseBlock4: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  responseBlock5: {
    [commonTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
    '& .MuiTypography-root': {
      [commonTheme.breakpoints.down('sm')]: {
        width: '100%',
        maxWidth: '100%',
        margin: 0,
      },
    },
  },
  responseTtl: {
    lineHeight: 1.6,
    [commonTheme.breakpoints.down('sm')]: {
      width: '22%',
    },
  },
  responseTxtWrap: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    [commonTheme.breakpoints.down('sm')]: {
      width: '78%',
    },
  },
  responseTxt: {
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: 0,
    marginLeft: 4,
    [commonTheme.breakpoints.down('sm')]: {
      maxWidth: '76%',
    },
  },
});

export default function CustomerDetailResponse(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const {
    input,
  } = props;

  return (
    <Grid className={classes.root}>
      <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
        反響情報
      </Typography>
      <Grid className={classes.response}>
        <Grid className={classes.responseBox}>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock1}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              Web会員ID/会員状態
            </Typography>
            <Grid className={classes.responseTxtWrap}>
              {input.detail.webs.map((data, index) => (
                <Typography key={data.memberId} className={classes.responseTxt}>
                  {data.memberId}({MEMBER_STATUS_CODE[data.memberStatusCode]})
                  {((index + 1) !== input.detail.webs.length) && '、'}
                </Typography>
              ))}
            </Grid>
          </Grid>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock2}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              源泉獲得者
            </Typography>
            <Typography className={classes.responseTxt}>
              {input.detail.acquisitionId
                ? input.detail.acquisitionName
                : input.detail.acquisitionMemo}
            </Typography>
          </Grid>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock2}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              獲得者種
            </Typography>
            <Typography className={classes.responseTxt}>
              {ACQUISITION_CODE[input.detail.acquisitionCode]}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.responseBox}>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock2}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              反響日
            </Typography>
            <Typography className={classes.responseTxt}>
              {input.detail.effectAt && input.detail.effectAt.substr(0, 10)}
            </Typography>
          </Grid>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock3}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              反響媒体（大）
            </Typography>
            <Typography className={classes.responseTxt}>
              {input.detail.largeResponseTypeName}
            </Typography>
          </Grid>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock3}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              反響媒体（中）
            </Typography>
            <Typography className={classes.responseTxt}>
              {input.detail.mediumResponseTypeName}
            </Typography>
          </Grid>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock3}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              反響媒体（小）
            </Typography>
            <Typography className={classes.responseTxt}>
              {input.detail.smallResponseTypeName}
            </Typography>
          </Grid>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock3}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              反響媒体（細）
            </Typography>
            <Typography className={classes.responseTxt}>
              {input.detail.fineResponseTypeName}
            </Typography>
          </Grid>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock4}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              反響媒体
            </Typography>
            <Typography className={classes.responseTxt}>
              {input.detail.largeResponseTypeName}{' / '}
              {input.detail.mediumResponseTypeName}{' / '}
              {input.detail.smallResponseTypeName}{' / '}
              {input.detail.fineResponseTypeName}
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.responseBox}>
          <Grid className={`${classes.responseBlock} ${classes.responseBlock5}`}>
            <Typography className={`${baseClasses.title6} ${classes.responseTtl}`}>
              当社を知ったきっかけ
            </Typography>
            <Typography className={classes.responseTxt}>
              {input.detail.reasonKnowOh}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
