import client from '../httpClient';

// division005に有効なKey群
const VALID_KEYS = [
  'typeCode',
  'unitCode',
  'level',
  'divisionId',
  'userLevelMin',
  'userLevelMax',
  'isWebHistoryExist',
  'isSendableMailExist',
  'isCallablePhoneNumberExist',
  'isDirectExist',
  'isWebSearchOption',
  'customerStatusCode',
  'isReceiveShare',
  'isCalledAndNotMailed',
  'isSendShare',
  'actionFrom',
  'actionTo',
  'actions',
  'actionKeyWord',
  'actionSearchCode',
  'responseFrom',
  'responseTo',
  'effectFrom',
  'effectTo',
  'largeResponseTypeId',
  'mediumResponseTypeId',
  'smallResponseTypeId',
  'fineResponseTypeId',
  'memberStatusCode',
  'houseBudgetMin',
  'houseBudgetMax',
  'livingSpaceMin',
  'livingSpaceMax',
  'landBudgetMin',
  'landBudgetMax',
  'wishLandAreaMin',
  'wishLandAreaMax',
  'budgetMin',
  'budgetMax',
  'floorPlan',
  'annualIncomeMin',
  'annualIncomeMax',
  'isThinkSale',
  'isThinkApartment',
  'isThinkWholeHouseInvestment',
  'isThinkDivisionInvestment',
  'isThinkRebuilding',
  'wishAreaIds',
  'wishAreaCode',
  'isNoneWishArea',
  'wishAreaSearchTypeCode',
  'wishRailwayId',
  'wishRailwayIdSearchTypeCode',
  'wishStationFrom',
  'wishStationTo',
  'searchStationTypeCode',
  'mailOpenHistoryCode',
  'isGuidanceHistorySearch',
  'isVisitHistorySearch',
  'holidayWeekdays',
  'telStatusCode',
  'phoneSupportStatusCode',
  'emailStatusCodes',
  'mailSendStatusCode',
  'isAddressExist',
  'abodeNowBuildingType',
  'isAllowDm',
  'currentUserId',
  'currentUserDivisionId',
  'formerUserId',
  'formerUserDivisionId',
  'previousUserId',
  'previousUserDivisionId',
  'thinkStartSeasonFrom',
  'thinkStartSeasonTo',
  'noContactDetailReasonCode',
  'noneContactKeywordCodes',
  'noneContactKeyword',
  'keyword',
  'keywordSearch',
  'recommendStatus',
  'customerIds',
  'convenientTimes',
  'lastActionDate',
  'userLevel',
  'lastAccessDate',
  'displayOrderCode',
  'searchType2',
  'searchType3',
  'searchType4',
  'searchType5',
  'guidanceCountMin',
  'guidanceCountMax',
  'isContactOrReceptionSearch',
  'isAvoidSharedCustomer',
  'acquisitions',
];

const divisionsGetResponseTreeApi = (params, isCustomers = false) => {
  const copyObj = Object.assign({}, params);
  if (!isCustomers) {
    for (const p in copyObj) {
      VALID_KEYS.includes(p) ? null : delete copyObj[p];
      if (copyObj[p] instanceof Object) {
        copyObj[p].length === 0 ? delete copyObj[p] : null;
      } else if (copyObj[p] === '' || copyObj[p] === null) {
        delete copyObj[p];
      }
    }
  }
  return client.post('/divisions/customer-tree/', JSON.stringify(copyObj), { isShowLoading: false });
};

export default divisionsGetResponseTreeApi;
