import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, FormControlLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import commonStyles from '../../styles';

import UserTree from '../../common/userTreeNew';
import LibraryDirectoryTree from '../../common/libraryDirectoryTree';

import { setLibrarySearchParamWhole } from '../../../store/libraryPage';
import { TextBaseField } from '../../eleCommon/validation';
import { validateFormString1 } from '../../../commonFunction/validations';

const useStyles = makeStyles({
  root: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: '8px',
  },
  inputText: {
    width: '100%',
  },
  inputButton: {
    width: '100%',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,
    background: '#fff',
    justifyContent: 'flex-start',
    padding: '6px 0',
    marginTop: '16px',
    color: 'rgba(0, 0, 0, 0.42)',
    '&:hover': {
      background: '#fff',
      color: 'rgba(0, 0, 0)',
      borderColor: 'rgba(0, 0, 0)',
    },
  },
  marginRight: { marginRight: '16px' },
  isWithin7Days: {
    padding: '12px 6px',
  },
  isWithin7DaysLabel: {
    '& .MuiFormControlLabel-label': {
      marginLeft: 4,
    },
  },
});

function InputText(props) {
  const { value, search, setSearch, errorCallback } = props;
  const classes = useStyles();
  const common = commonStyles();
  const onBlur = (e) => {
    setSearch({ ...search, [value.id]: e.target.value });
  };
  return (
    <TextBaseField
      className={classes.inputText}
      onChange={onBlur}
      value={search[value.id] || ''}
      label={
        <Typography className={common.small}>{value.label}</Typography>
      }
      validator={value.id === 'libraryMemo' ? null : validateFormString1({ maxLengthInt: 250 })}
      name={value.label}
      errorCallback={errorCallback(value.label)}
    />
  );
}

function InputButton(props) {
  const { value, search, setOpen } = props;
  const classes = useStyles();
  const common = commonStyles();
  const onClick = () => setOpen(true);
  return (
    <TextField
      className={`${common.modalBtn} ${classes.inputText}`}
      onClick={onClick}
      value={search[value.name] || ''}
      label={
        <Typography className={common.small}>
          {value.label}
        </Typography>
      }
    />
  );
}

export default function LibrarySearch(props) {
  const { functions } = props;
  const classes = useStyles();
  const common = commonStyles();

  const dispatch = useDispatch();

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };
  // デフォルトライブラリを「全体」に指定 ※stg/prodのID OH_KDSFA-431
  const initialSearchParam = {
    libraryDirectoryId: 4895,
    libraryDirectoryName: '全体',
  };
  const [searchParam, setSearchParam] = useState(initialSearchParam);

  // 連打防止フラグ
  const [clickFlg, setClickFlg] = useState(false);

  // ライブラリツリーウィンドウ
  const [isOpenDirectoryTree, setIsOpenDirectoryTree] = useState(false);
  const [selectDir, setSelectDir] = useState({});
  // ライブラリ担当者ウィンドウ
  const [isOpenUser, setIsOpenUser] = useState(false);
  const [selectUser, setSelectUser] = useState({});
  const [selectDivision, setSelectDivision] = useState({});
  // 検索項目一覧定義
  const inputlist = {
    folder: {
      label: 'ライブラリフォルダー',
      id: 'libraryDirectoryId',
      name: 'libraryDirectoryName',
    },
    libraryName: {
      label: 'ライブラリ名（部分一致）',
      id: 'libraryName',
    },
    libraryMemo: {
      label: 'メモ（部分一致）',
      id: 'libraryMemo',
    },
    mailSubject: {
      label: '件名（部分一致）',
      id: 'mailSubject',
    },
    updatedBy: {
      label: 'ライブラリ更新者',
      id: 'updatedBy',
      name: 'updatedByName',
    },
  };

  // ライブラリーフォルダー選択時
  const onSelectDirectory = (directory) => {
    setSelectDir(directory);
    setSearchParam((s) => ({
      ...s,
      libraryDirectoryId: directory.libraryDirectoryId,
      libraryDirectoryName: directory.directoryName,
    }));
  };

  // リセット押下時
  const onClickReset = () => setSearchParam(initialSearchParam);

  // 検索押下時
  const onClickSearch = async () => {
    if (clickFlg) return;
    setClickFlg(true);
    const tmp = { ...searchParam };
    delete tmp.updatedByName;
    if (tmp.isWithin7Days) {
      tmp.isWithin7Days = 1;
    } else {
      delete tmp.isWithin7Days;
    }
    dispatch(setLibrarySearchParamWhole(tmp));
    await functions.getLibraries();
    setClickFlg(false);
  };

  useEffect(() => {
    if (!selectUser.userId) {
      // ユーザーが選択されていないため初期化
      setSearchParam((s) => ({ ...s, updatedBy: undefined, updatedByName: '' }));
    } else {
      setSearchParam((s) => ({
        ...s,
        updatedBy: selectUser.userId,
        updatedByName: selectDivision.divisionName
          ? `${selectDivision.divisionName} ${selectUser.userName}`
          : selectUser.userName,
      }));
    }
  }, [selectUser]);

  return (
    <Grid className={classes.root}>
      <Grid>
        <InputButton
          value={inputlist.folder}
          search={searchParam}
          setOpen={setIsOpenDirectoryTree}
        />
        <LibraryDirectoryTree
          selectDir={selectDir}
          setSelectDir={onSelectDirectory}
          isTopRootDirectory
          prefix="selctWindowliburaryDirectoryId"
          isGetLatestData={false}
          isSearch
          modalOption={{
            open: isOpenDirectoryTree,
            onClose: () => setIsOpenDirectoryTree(false),
            title: 'ライブラリのフォルダ選択ウィンドウ',
            message: 'ライブラリフォルダーを選択してください。',
          }}
        />
      </Grid>
      <Grid>
        <InputText
          value={inputlist.libraryName}
          search={searchParam}
          setSearch={setSearchParam}
          errorCallback={errorCallback}
        />
      </Grid>
      <Grid>
        <InputText
          value={inputlist.libraryMemo}
          search={searchParam}
          setSearch={setSearchParam}
          errorCallback={errorCallback}
        />
      </Grid>
      <Grid>
        <InputText
          value={inputlist.mailSubject}
          search={searchParam}
          setSearch={setSearchParam}
          errorCallback={errorCallback}
        />
      </Grid>
      <Grid>
        <InputButton
          value={inputlist.updatedBy}
          search={searchParam}
          setOpen={setIsOpenUser}
        />
        <UserTree
          isOpenTree={isOpenUser}
          setIsOpenTree={setIsOpenUser}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          isDialog
          isSelectUserWindow
        />
      </Grid>
      <Grid
        container
        justifyContent="flex-start"
        className={classes.isWithin7Days}
      >
        <FormControlLabel
          className={classes.isWithin7DaysLabel}
          control={
            <Checkbox
              color="primary"
              checked={Boolean(searchParam.isWithin7Days)}
              onChange={() => setSearchParam((s) => ({ ...s, isWithin7Days: !s.isWithin7Days }))}
            />
          }
          label={
            <Typography className={common.small}>
              更新７日以内のライブラリ
            </Typography>
          }
        />
      </Grid>
      <Grid container justifyContent="center">
        <Button
          onClick={onClickReset}
          className={`${common.buttonsSecondary} ${classes.marginRight}`}
        >
          リセット
        </Button>
        <Button
          onClick={onClickSearch}
          className={common.buttonsPrimary}
          disabled={errorSet.size !== 0 || clickFlg}
        >
          検索
        </Button>
      </Grid>
    </Grid>
  );
}
