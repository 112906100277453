import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableRow, makeStyles } from '@material-ui/core';
import CustomCell from './customCell';
import { crmPageActions, crmPageSelector } from '../../../store/crmPage';
import { JOIN_DATA_FROM_TYPES } from '../../../constants/crm';

const useStyles = makeStyles((theme) => ({
  row: {
    position: 'relative',
    '&:nth-of-type(even)': {
      background: '#F3F3F3',
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      '& .flyerSalesBtn': {
        opacity: 1,
      },
    },
  },
  selected: {
    background: `${theme.palette.primaryColor}29 !important`,
  },
  popover: {
    pointerEvents: 'none',
  },
  existing: { background: 'rgba(216, 52, 32, 0.08) !important' },
}));

export const basicFields = [
  // チェックボックスを表示したい場合
  // { title: 'No', key: 'responseId', type: 'checkbox' },
  { title: 'No', key: 'index' },
  { title: '日時', key: 'receptAt' },
  { title: '担当者', key: 'responsibleUser' },
  { title: '源泉獲得者', key: 'acquisitionUser' },
  { title: '顧客姓', key: 'customerLastName', fontWeight: '700', checkKey: 'isCustomerName' },
  { title: '顧客名', key: 'customerFirstName', fontWeight: '700', checkKey: 'isCustomerName' },
  { title: 'TEL', key: 'phoneNumber', checkKey: 'isPhoneNumber' },
  { title: '補足', key: 'phoneNumberMemo' },
  { title: 'Mail', key: 'mail', checkKey: 'isMail' },
  { title: '補足', key: 'mailMemo' },
  { title: '家族構成', key: 'familyStructure' },
];

function ResponseRow(props) {
  const {
    row,
    selected,
    // responseTypeList,
    index,
    onClickRegister,
    onClickDelete,
  } = props;

  const classes = useStyles();

  let className = classes.row;
  if (row.isWebMemberId || row.isCustomerName || row.isPhoneNumber || row.isMail) {
    className += ` ${classes.existing}`;
  }

  const dispatch = useDispatch();
  const { joinData } = useSelector(crmPageSelector);

  const onCellClick = useCallback((e, { key }) => {
    e.stopPropagation();
    // 姓、名、電話番号、メールのCellがクリックされ、
    // かつnullではない場合
    if (
      ['customerLastName', 'customerFirstName', 'phoneNumber', 'mail'].includes(key)
      && row[key]
    ) {
      // 姓、名の場合、フルネームをキーワードにする
      const searchWord = ['customerLastName', 'customerFirstName'].includes(key)
        // フルネーム
        ? (row.customerLastName || '') + (row.customerFirstName || '')
        // 電話番号、メール
        : row[key];

      dispatch(crmPageActions.setJoinData({
        webResponseData: row,
        childResponseId: row.responseId,
        isShow: false,
        fromType: JOIN_DATA_FROM_TYPES.FLYER_SALES_LIST,
      }));

      dispatch(crmPageActions.setSearchKeyword({ searchWord }));
      // dispatch(crmPageActions.setSelectedCustomerRow(null));
    } else {
      // その以外の場合には行の選択をキャンセルする
      dispatch(crmPageActions.setJoinData());
    }
  }, []);

  return (
    <TableRow
      id={`idResponse-${row.responseId}`}
      aria-haspopup="true"
      selected={selected || row.responseId === joinData.webResponseData?.responseId}
      className={className}
      classes={{ selected: classes.selected }}
    >
      {basicFields.map((obj, i) => (
        <CustomCell
          key={`${row.responseId}-${obj.key}`}
          obj={obj}
          row={row}
          index={index}
          tdIndex={i}
          onClickRegister={onClickRegister}
          onClickDelete={onClickDelete}
          onClick={(e) => onCellClick(e, obj)}
        />
      ))}
    </TableRow>
  );
}

export default ResponseRow;
