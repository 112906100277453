import { Grid } from '@material-ui/core';
import { useState } from 'react';

import { ACQUISITION_CODE } from '../../../constants';

import DraggableDialog from '../../eleCommon/draggableDialog';
import AddAcquisition, { getDisplayAcquisitionName } from './addAcquisition';
import AddResponseType from './addResponceType';
import AddUser from './addUSer';
import gensenStyle from './gensenRegisterStyles';
import ListItem from './listItem';

export default function AddGetUser(props) {
  const { data, setData, readOnly } = props;
  const gCommon = gensenStyle();
  const [open, setOpen] = useState({
    aq: false,
    code: false,
    user: false,
  });
  return (
    <Grid className={gCommon.blockWrap}>
      <ListItem
        readOnly={readOnly}
        label="源泉獲得者"
        required
        onClick={() => setOpen({ ...open, aq: true })}
      >
        {getDisplayAcquisitionName(data, '-')}
      </ListItem>
      <DraggableDialog
        className={gCommon.draggableDialog}
        open={open.aq}
        onClose={() => {
          setOpen({ ...open, aq: false });
        }}
      >
        <AddAcquisition
          onClose={() => {
            setOpen({ ...open, aq: false });
          }}
          local={data}
          setLocal={setData}
        />
      </DraggableDialog>
      <ListItem readOnly label="区分" disabled={!readOnly}>
        {data.acquisitionCode ? ACQUISITION_CODE[data.acquisitionCode] : '-'}
      </ListItem>
      <ListItem
        readOnly={readOnly}
        label="担当者"
        required
        onClick={() => setOpen({ ...open, user: true })}
      >
        {data.responsibleUserName || '-'}
      </ListItem>
      <DraggableDialog
        className={gCommon.draggableDialog}
        open={open.user}
        onClose={() => {
          setOpen({ ...open, user: false });
        }}
      >
        <AddUser
          onClose={() => {
            setOpen({ ...open, user: false });
          }}
          local={data}
          setLocal={setData}
        />
      </DraggableDialog>
      <AddResponseType data={data} setData={setData} readOnly={readOnly} />
    </Grid>
  );
}
