const conversionCustomerData = (customerData) => {
  const keys = Object.keys(customerData);
  Object.values(customerData).forEach((item, number) => {
    const key = keys[number];
    if (item === '' || !item) {
      customerData[keys[number]] = null;
    } else if (key === 'genderCode') {
      if (item === '男性') {
        customerData.genderCode = 1;
      } else if (item === '女性') {
        customerData.genderCode = 2;
      }
    } else if (key === 'subGenderCode') {
      if (item === '男性') {
        customerData.subGenderCode = 1;
      } else if (item === '女性') {
        customerData.subGenderCode = 2;
      }
    } else if (key === 'isPermanentResidency') {
      if (item === 'なし') {
        customerData.isPermanentResidency = 0;
      } else if (item === 'あり') {
        customerData.isPermanentResidency = 1;
      }
    } else if (key === 'isSubPermanentResidency') {
      if (item === 'なし') {
        customerData.isSubPermanentResidency = 0;
      } else if (item === 'あり') {
        customerData.isSubPermanentResidency = 1;
      }
    } else if (key === 'workingStyleCode') {
      if (item === '未選択') {
        customerData.workingStyleCode = 0;
      } else if (item === '出社') {
        customerData.workingStyleCode = 1;
      }
      if (item === '在宅') {
        customerData.workingStyleCode = 2;
      } else if (item === '在宅／出社混合') {
        customerData.workingStyleCode = 3;
      }
    } else if (key === 'subWorkingStyleCode') {
      if (item === '未選択') {
        customerData.subWorkingStyleCode = 0;
      } else if (item === '出社') {
        customerData.subWorkingStyleCode = 1;
      } else if (item === '在宅') {
        customerData.subWorkingStyleCode = 2;
      } else if (item === '在宅／出社混合') {
        customerData.subWorkingStyleCode = 3;
      }
    } else if (key === 'floorPlanCode') {
      if (item === '2LDK以下') {
        customerData.floorPlanCode = 4;
      } else if (item === '2SLDK・3LDK') {
        customerData.floorPlanCode = 8;
      } else if (item === '3SLDK・4LDK') {
        customerData.floorPlanCode = 16;
      } else if (item === '5LDK以上') {
        customerData.floorPlanCode = 32;
      }
    } else if (key === 'judgeIsThinkSale') {
      if (item === '検討しない') {
        customerData.judgeIsThinkSale = 0;
      } else if (item === '検討する') {
        customerData.judgeIsThinkSale = 1;
      }
    } else if (key === 'isThinkApartment') {
      if (item === '検討しない') {
        customerData.isThinkApartment = 0;
      } else if (item === '検討する') {
        customerData.isThinkApartment = 1;
      }
    } else if (key === 'isThinkWholeHouseInvestment') {
      if (item === '検討しない') {
        customerData.isThinkWholeHouseInvestment = 0;
      } else if (item === '検討する') {
        customerData.isThinkWholeHouseInvestment = 1;
      }
    } else if (key === 'isThinkDivisionInvestment') {
      if (item === '検討しない') {
        customerData.isThinkDivisionInvestment = 0;
      } else if (item === '検討する') {
        customerData.isThinkDivisionInvestment = 1;
      }
    } else if (key === 'isThinkRebuilding') {
      if (item === '検討しない') {
        customerData.isThinkRebuilding = 0;
      } else if (item === '検討する') {
        customerData.isThinkRebuilding = 1;
      }
    } else if (key === 'isCarPark') {
      if (item === '希望しない') {
        customerData.isCarPark = 0;
      } else if (item === '希望する') {
        customerData.isCarPark = 1;
      }
    } else if (key === 'abodeNowFloorPlanCode') {
      if (item === '1K') {
        customerData.abodeNowFloorPlanCode = 1;
      } else if (item === '1LDK') {
        customerData.abodeNowFloorPlanCode = 2;
      } else if (item === '2LDK以下') {
        customerData.abodeNowFloorPlanCode = 4;
      } else if (item === '2SLDK・3LDK') {
        customerData.abodeNowFloorPlanCode = 8;
      } else if (item === '3SLDK・4LDK') {
        customerData.abodeNowFloorPlanCode = 16;
      } else if (item === '5LDK以上') {
        customerData.abodeNowFloorPlanCode = 32;
      }
    } else if (key === 'abodeNowReplacementCode') {
      if (item === '未回答') {
        customerData.abodeNowReplacementCode = 0;
      } else if (item === '買替有') {
        customerData.abodeNowReplacementCode = 1;
      } else if (item === '買替無') {
        customerData.abodeNowReplacementCode = 2;
      }
    } else if (key === 'isNoCustomer') {
      if (item === '顧客である') {
        customerData.isNoCustomer = 0;
      } else if (item === '顧客でない') {
        customerData.isNoCustomer = 1;
      }
    } else if (key === 'customerStatusCode') {
      if (item === '追客中') {
        customerData.customerStatusCode = 1;
      } else if (item === '追わない') {
        customerData.customerStatusCode = 2;
      } else if (item === '契約') {
        customerData.customerStatusCode = 10;
      } else if (item === 'クレーム') {
        customerData.customerStatusCode = 11;
      }
    } else if (key === 'telStatusCode') {
      if (item === '電話NG') {
        customerData.telStatusCode = 0;
      } else if (item === '電話OK') {
        customerData.telStatusCode = 1;
      } else if (item === '誤登録') {
        customerData.telStatusCode = 2;
      } else if (item === '制限あり') {
        customerData.telStatusCode = 3;
      }
    } else if (key === 'emailStatusCode') {
      if (item === 'ダメ全部NG') {
        customerData.emailStatusCode = 0;
      } else if (item === '個別のみOK') {
        customerData.emailStatusCode = 1;
      } else if (item === '一斉・個別OK') {
        customerData.emailStatusCode = 3;
      } else if (item === '代行も全てOK') {
        customerData.emailStatusCode = 5;
      }
    } else if (key === 'userLevel') {
      if (item === '★なし') {
        customerData.userLevel = 0;
      } else if (item === '★') {
        customerData.userLevel = 1;
      } else if (item === '★★') {
        customerData.userLevel = 2;
      } else if (item === '★★★') {
        customerData.userLevel = 3;
      } else if (item === '★★★★') {
        customerData.userLevel = 4;
      } else if (item === '★★★★★') {
        customerData.userLevel = 5;
      }
    } else if (key === 'acquisitionCode') {
      if (item === '社員') {
        customerData.acquisitionCode = 1;
      } else if (item === '内定者') {
        customerData.acquisitionCode = 2;
      } else if (item === '研修生') {
        customerData.acquisitionCode = 3;
      } else if (item === 'アルバイト') {
        customerData.acquisitionCode = 4;
      } else if (item === 'トライアル') {
        customerData.acquisitionCode = 6;
      }
      // acquisitionIdが設定されている時は、acquisitionMemoをクリアする
      if (Number(customerData.acquisitionId)) {
        customerData.acquisitionMemo = null;
      }
    } else if (key === 'abodeNowBuildingType') {
      if (item === '0') {
        customerData.abodeNowBuildingType = null;
      } else if (item === '1') {
        customerData.abodeNowBuildingType = '実家';
      } else if (item === '2') {
        customerData.abodeNowBuildingType = '持家';
      } else if (item === '3') {
        customerData.abodeNowBuildingType = '賃貸';
      } else if (item === '4') {
        customerData.abodeNowBuildingType = '社宅';
      }
    }
  });
  return customerData;
};

export default conversionCustomerData;
