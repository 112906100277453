/* eslint-disable max-len */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Add, Cancel } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import store from '../../../../store';
import commonStyles from '../../../styles';
import { IS_SHAIN, USE_AQCUISITION_TREE_GROUP } from '../../../../constants';
import UserTree from '../../../common/userTreeNew';
import commonTheme from '../../../styles/theme';
import DraggableDialog from '../../../eleCommon/draggableDialog';
import AddAcquisition from '../../../gensenRegister/parts/addAcquisition';

const useStyles = makeStyles((theme) => ({
  title: {
    whiteSpace: 'nowrap',
    textAlign: 'left',
    borderRadius: 0,
    paddingLeft: 8,
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '900',
    lineHeight: 1.3,
    letterSpacing: 0,
    marginLeft: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  btn: {
    fontFamily: 'Roboto',
    [commonTheme.breakpoints.up('md')]: {
      fontSize: '13px',
      width: '246px',
      borderBottom: '1px #8c8c8c solid',
      paddingBottom: '9px',
      borderRadius: 0,
      background: 'transparent top 50% right 5px/17px no-repeat',
      backgroundImage: 'url(\'data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%238c8c8c" d="M7 10l5 5 5-5z" /></svg>\')',
      '&:hover': {
        backgroundColor: `${theme.palette.primaryColor}29`,
        borderColor: '#000',
      },
    },
    [commonTheme.breakpoints.down('sm')]: {
      lineHeight: 1.6,
      fontWeight: '700',
      width: '100%',
      marginTop: '0px',
      marginBottom: '0px',
      padding: '16px',
      flexShrink: 0,
      borderBottom: '1px solid #F3F3F3',
      textTransform: 'none',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
  },
  buttonLabel: {
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 16,
      flexShrink: 0,
    },
  },
  buttonText: {
    marginLeft: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  buttonTextList: {
    display: 'block',
    '& > *': {
      display: 'block',
    },
  },
  icon: {
    color: theme.palette.primaryColor,
    width: '16px',
    flexShrink: 0,
  },
  addButtonIcon: {
    marginLeft: 'auto',
    fill: '#C8C8C8',
  },
  itemButton: {
    lineHeight: 1.6,
    fontWeight: '700',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    [commonTheme.breakpoints.down('sm')]: {
      textTransform: 'none',
    },
    '& .MuiButton-label': {
      justifyContent: 'flex-start',
    },
    '&>span': {
      fontSize: 13,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '&.disabled': {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  },
}));

function SearchAcquisition(props) {
  const { values, search } = props;
  const { responseHeader } = store.getState();

  const classes = useStyles();
  const baseClasses = commonStyles();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  const initial = {
    userId: search.acquisitions?.acquisitionUserId || responseHeader.userId,
    userName: search.acquisitionName || responseHeader.userName,
  };

  // ツリー内で使われる（顧客メイン上には見せない）データ
  const [selectUser, setSelectUser] = useState(initial);
  const [selectDivision, setSelectDivision] = useState({});
  const [displayNameInTree, setDisplayNameInTree] = useState();
  const [initialAcquirer, setInitialAcquirer] = useState(search.acquisitions?.acquisitionCode ?? String(IS_SHAIN));

  // 獲得者がツリーから選択できるか
  const isTree = USE_AQCUISITION_TREE_GROUP.includes(Number(initialAcquirer));

  // モーダル開閉用のローカルステート
  const [open, setOpen] = useState(false);
  // モーダルを開くsetState
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelectFunction = () => {
    // 獲得者を更新する
    values.input.setState({
      acquisitions: {
        acquisitionUserId: isTree
          ? selectUser.userId
          : null,
        acquisitionCode: Number(initialAcquirer),
        acquisitionMemo: !isTree
          ? displayNameInTree
          : null,
      },
      acquisitionName: isTree ? selectUser.userName : displayNameInTree,
    });
  };

  const handleSelectFunctionSp = (params) => {
    const isTreeSp = USE_AQCUISITION_TREE_GROUP.includes(Number(params.acquisitionCode));
    // 獲得者を更新する
    values.input.setState({
      acquisitions: {
        acquisitionUserId: isTreeSp
          ? params.acquisitionUserId
          : null,
        acquisitionCode: Number(params.acquisitionCode),
        acquisitionMemo: !isTreeSp
          ? params.acquisitionMemo
          : null,
      },
      acquisitionName: isTreeSp ? params.acquisitionUserFirstName : params.acquisitionMemo,
    });
  };

  const handleClearFunction = () => {
    // 獲得者の表示を初期化
    setDisplayNameInTree('');
    setInitialAcquirer(String(IS_SHAIN));
    setSelectUser(initial);
    values.input.setState({
      acquisitions: '',
      acquisitionName: '',
    });
  };

  const getItemListSp = () => {
    return (
      <Button
        onClick={handleOpen}
        className={classes.itemButton}
      >
        <span className={classes.buttonLabel}>
          {values.input.label}
        </span>
        <span className={classes.buttonText}>
          <span className={classes.buttonTextList}>
            {values.input.state}
          </span>
          {search.acquisitions?.acquisitionCode ? (
            <Cancel
              className={`${classes.icon} ${classes.cancel}`}
              onClick={(e) => {
                e.stopPropagation();
                handleClearFunction();
              }}
            />
          ) : (
            <Add className={classes.addButtonIcon} />
          )}
        </span>
      </Button>
    );
  };

  return (
    isSp ? (
      <>
        {getItemListSp()}
        <DraggableDialog
          className=""
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <AddAcquisition
            onClose={() => {
              setOpen(false);
            }}
            local={{
              ...search.acquisitions,
              acquisitionUserFirstName: search.acquisitionName,
            }}
            setLocal={handleSelectFunctionSp}
          />
        </DraggableDialog>
      </>
    ) : (
      <Grid className={classes.wrap}>
        <Button
          className={classes.btn}
          onClick={handleOpen}
        >
          <Typography className={`${baseClasses.title6} ${classes.title}`}>
            源泉獲得者
          </Typography>
          <Typography className={classes.txt}>
            {values.input.state}
          </Typography>
        </Button>
        <UserTree
          selectUser={selectUser}
          setSelectUser={setSelectUser}
          selectDivision={selectDivision}
          setSelectDivision={setSelectDivision}
          isOpenTree={open}
          setIsOpenTree={setOpen}
          handleSelectFunction={handleSelectFunction}
          handleClearFunction={handleClearFunction}
          isDialog
          isSelectUserWindow
          isAcquirer
          initialAcquirer={initialAcquirer}
          setInitialAcquirer={setInitialAcquirer}
          setDisplayName={setDisplayNameInTree}
          defaultDisplayName={displayNameInTree}
        />
      </Grid>
    )
  );
}

export default SearchAcquisition;
