import {
  GENDER_CODE,
  PERMANENT_RESIDENCY,
  WORKING_STYLE_TYPE,
  IS_WISH_MANSION,
  IS_THINK_WHOLE_HOUSE_INVESTMENT,
  IS_THINK_DIVISION_INVESTMENT,
  IS_THINK_SALE,
  IS_INVESTMENT1,
  IS_REPLACEMENT,
  CUSTOMER_STATUS_CODE,
  USER_LEVEL,
  PREFECTURES_CODE,
  CURRENT_SITUATION_TYPE,
  ABODE_NOW_FLOOR_PLAN,
  TEL_STATUS_CODE,
  EMAIL_STATUS_CODE,
  FLOOR_PLAN,
  BUDGET_SELECT,
  IS_THINK_REBUILDING,
} from '../../../constants';

const getTextValue = (mapObj, key) => {
  return key in mapObj ? mapObj[key] : '';
};

const getCodeValue = (mapObj, val) => {
  const code = Object.keys(mapObj).find((key) => {
    return mapObj[key] === val;
  });
  return code || 0;
};

const headers = {
  shimei: '氏名',
  contactAdress: '連絡先',
  adress: '現住所',
  family: '家族構成',
  company: '勤務先',
  budjet: '予算',
  hope: '希望',
  situation: '現況',
  response: '反響情報',
  inHouse: '社内情報',
  googleDrive: 'Googleドライブ',
};

const convertFormData = (customersDataParent, customersDataChild) => {
  const customeCustomerStatusCode = { ...CUSTOMER_STATUS_CODE };
  if (
    customersDataParent.customerStatusCode === 10 // 契約
    || customersDataParent.customerStatusCode === 11 // クレーム
  ) {
    Object.keys(customeCustomerStatusCode).forEach((k) => {
      if (Number(k) === customersDataParent.customerStatusCode) return;
      delete customeCustomerStatusCode[Number(k)];
    });
  } else {
    delete customeCustomerStatusCode[10];
    delete customeCustomerStatusCode[11];
  }
  return [
    {
      label: '顧客ID',
      required: false,
      type: 'id',
      parentContent: customersDataParent.customerId,
      childContent: customersDataChild.customerId,
      variableName: 'customerId',
    },
    {
      label: '姓',
      required: true,
      type: 'shimei',
      header: headers.shimei,
      parentContent: customersDataParent.lastName,
      childContent: customersDataChild.lastName,
      variableName: 'lastName',
      inputType: 'text',
    },
    {
      label: '名',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.firstName,
      childContent: customersDataChild.firstName,
      variableName: 'firstName',
      inputType: 'text',
    },
    {
      label: 'カナ姓',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.lastNameKana,
      childContent: customersDataChild.lastNameKana,
      variableName: 'lastNameKana',
      inputType: 'text',
    },
    {
      label: 'カナ名',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.firstNameKana,
      childContent: customersDataChild.firstNameKana,
      variableName: 'firstNameKana',
      inputType: 'text',
    },
    {
      label: '性別',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.genderCode,
      parentDisplayName: getTextValue(GENDER_CODE, customersDataParent.genderCode),
      childContent: customersDataChild.genderCode,
      childDisplayName: getTextValue(GENDER_CODE, customersDataChild.genderCode),
      map: GENDER_CODE,
      variableName: 'genderCode',
    },
    {
      label: '誕生日',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.birthday,
      childContent: customersDataChild.birthday,
      variableName: 'birthday',
    },
    {
      label: '年齢',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.age,
      childContent: customersDataChild.age,
      variableName: 'age',
      inputType: 'text',
    },
    {
      label: '永住権',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.isPermanentResidency,
      parentDisplayName: getTextValue(
        PERMANENT_RESIDENCY,
        customersDataParent.isPermanentResidency,
      ),
      childContent: customersDataChild.isPermanentResidency,
      childDisplayName: getTextValue(
        PERMANENT_RESIDENCY,
        customersDataChild.isPermanentResidency,
      ),
      map: PERMANENT_RESIDENCY,
      variableName: 'isPermanentResidency',
    },
    {
      label: '国籍',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.nationality,
      childContent: customersDataChild.nationality,
      variableName: 'nationality',
      inputType: 'text',
    },
    {
      label: '副姓',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.subLastName,
      childContent: customersDataChild.subLastName,
      variableName: 'subLastName',
      inputType: 'text',
    },
    {
      label: '副名',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.subFirstName,
      childContent: customersDataChild.subFirstName,
      variableName: 'subFirstName',
      inputType: 'text',
    },
    {
      label: '副カナ姓',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.subLastNameKana,
      childContent: customersDataChild.subLastNameKana,
      variableName: 'subLastNameKana',
      inputType: 'text',
    },
    {
      label: '副カナ名',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.subFirstNameKana,
      childContent: customersDataChild.subFirstNameKana,
      variableName: 'subFirstNameKana',
      inputType: 'text',
    },
    {
      label: '副性別',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.subGenderCode,
      parentDisplayName: getTextValue(GENDER_CODE, customersDataParent.subGenderCode),
      childContent: customersDataChild.subGenderCode,
      childDisplayName: getTextValue(GENDER_CODE, customersDataChild.subGenderCode),
      map: GENDER_CODE,
      variableName: 'subGenderCode',
    },
    {
      label: '副誕生日',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.subBirthday,
      childContent: customersDataChild.subBirthday,
      variableName: 'subBirthday',
    },
    {
      label: '副年齢',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.subAge,
      childContent: customersDataChild.subAge,
      variableName: 'subAge',
      inputType: 'text',
    },
    {
      label: '副永住権',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.isSubPermanentResidency,
      parentDisplayName: getTextValue(
        PERMANENT_RESIDENCY,
        customersDataParent.isSubPermanentResidency,
      ),
      childContent: customersDataChild.isSubPermanentResidency,
      childDisplayName: getTextValue(
        PERMANENT_RESIDENCY,
        customersDataChild.isSubPermanentResidency,
      ),
      map: PERMANENT_RESIDENCY,
      variableName: 'isSubPermanentResidency',
    },
    {
      label: '副国籍',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.subNationality,
      childContent: customersDataChild.subNationality,
      variableName: 'subNationality',
      inputType: 'text',
    },
    {
      label: '関係',
      required: false,
      type: 'shimei',
      parentContent: customersDataParent.relation,
      childContent: customersDataChild.relation,
      variableName: 'relation',
      inputType: 'text',
    },
    {
      groupName: 'お休みの日',
      header: headers.contactAdress,
      inputGroup: [
        {
          label: 'お休みの日',
          required: false,
          type: 'contactAdress',
          parentContent: customersDataParent.holidayWeekday,
          childContent: customersDataChild.holidayWeekday,
          variableName: 'holidayWeekday',
          inputType: 'modal',
        },
        {
          label: 'お休みの日 その他',
          required: false,
          type: 'contactAdress',
          parentContent: customersDataParent.otherHolidayWeekday,
          childContent: customersDataChild.otherHolidayWeekday,
          variableName: 'otherHolidayWeekday',
          inputType: 'modal',
        },
        {
          label: '連絡がつきやすい時間帯',
          required: false,
          type: 'contactAdress',
          parentContent: customersDataParent.convenientTime,
          childContent: customersDataChild.convenientTime,
          variableName: 'convenientTime',
          inputType: 'modal',
        },
        {
          label: '時間帯 その他',
          required: false,
          type: 'contactAdress',
          parentContent: customersDataParent.otherConvenientTime,
          childContent: customersDataChild.otherConvenientTime,
          variableName: 'otherConvenientTime',
          inputType: 'modal',
        },
      ],
    },
    {
      label: '電話番号1',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.phoneNumber1,
      childContent: customersDataChild.phoneNumber1,
      variableName: 'phoneNumber1',
      inputType: 'text',
    },
    {
      label: '電話番号補足1',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.phoneNumberMemo1,
      childContent: customersDataChild.phoneNumberMemo1,
      variableName: 'phoneNumberMemo1',
      inputType: 'text',
    },
    {
      label: '電話番号2',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.phoneNumber2,
      childContent: customersDataChild.phoneNumber2,
      variableName: 'phoneNumber2',
      inputType: 'text',
    },
    {
      label: '電話番号補足2',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.phoneNumberMemo2,
      childContent: customersDataChild.phoneNumberMemo2,
      variableName: 'phoneNumberMemo2',
      inputType: 'text',
    },
    {
      label: '電話番号3',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.phoneNumber3,
      childContent: customersDataChild.phoneNumber3,
      variableName: 'phoneNumber3',
      inputType: 'text',
    },
    {
      label: '電話番号補足3',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.phoneNumberMemo3,
      childContent: customersDataChild.phoneNumberMemo3,
      variableName: 'phoneNumberMemo3',
      inputType: 'text',
    },
    {
      label: '電話番号4',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.phoneNumber4,
      childContent: customersDataChild.phoneNumber4,
      variableName: 'phoneNumber4',
      inputType: 'text',
    },
    {
      label: '電話番号補足4',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.phoneNumberMemo4,
      childContent: customersDataChild.phoneNumberMemo4,
      variableName: 'phoneNumberMemo4',
      inputType: 'text',
    },
    {
      label: 'メールアドレス1',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.mail1,
      childContent: customersDataChild.mail1,
      variableName: 'mail1',
      inputType: 'text',
    },
    {
      label: 'メールアドレス補足1',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.mailMemo1,
      childContent: customersDataChild.mailMemo1,
      variableName: 'mailMemo1',
      inputType: 'text',
    },
    {
      label: 'メールアドレス2',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.mail2,
      childContent: customersDataChild.mail2,
      variableName: 'mail2',
      inputType: 'text',
    },
    {
      label: 'メールアドレス補足2',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.mailMemo2,
      childContent: customersDataChild.mailMemo2,
      variableName: 'mailMemo2',
      inputType: 'text',
    },
    {
      label: 'メールアドレス3',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.mail3,
      childContent: customersDataChild.mail3,
      variableName: 'mail3',
      inputType: 'text',
    },
    {
      label: 'メールアドレス補足3',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.mailMemo3,
      childContent: customersDataChild.mailMemo3,
      variableName: 'mailMemo3',
      inputType: 'text',
    },
    {
      label: 'メールアドレス4',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.mail4,
      childContent: customersDataChild.mail4,
      variableName: 'mail4',
      inputType: 'text',
    },
    {
      label: 'メールアドレス補足4',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.mailMemo4,
      childContent: customersDataChild.mailMemo4,
      variableName: 'mailMemo4',
      inputType: 'text',
    },
    {
      label: 'FAX',
      required: false,
      type: 'contactAdress',
      parentContent: customersDataParent.fax,
      childContent: customersDataChild.fax,
      variableName: 'fax',
      inputType: 'text',
    },
    {
      label: '郵便番号',
      required: false,
      type: 'adress',
      header: headers.adress,
      parentContent: customersDataParent.zip,
      childContent: customersDataChild.zip,
      variableName: 'zip',
      inputType: 'text',
    },
    {
      label: '都道府県',
      required: false,
      type: 'adress',
      parentContent: customersDataParent.prefecture,
      childContent: customersDataChild.prefecture,
      map: PREFECTURES_CODE,
      variableName: 'prefecture',
    },
    {
      label: '市区町村',
      required: false,
      type: 'adress',
      parentContent: customersDataParent.address1,
      childContent: customersDataChild.address1,
      variableName: 'address1',
      inputType: 'text',
    },
    {
      label: 'それ以降の住所',
      required: false,
      type: 'adress',
      parentContent: customersDataParent.address2,
      childContent: customersDataChild.address2,
      variableName: 'address2',
      inputType: 'text',
    },
    {
      label: 'マンション名',
      required: false,
      type: 'adress',
      parentContent: customersDataParent.building,
      childContent: customersDataChild.building,
      variableName: 'building',
      inputType: 'text',
    },
    {
      label: '家族構成 大人',
      required: false,
      type: 'family',
      header: headers.family,
      parentContent: customersDataParent.adultCount,
      childContent: customersDataChild.adultCount,
      variableName: 'adultCount',
      inputType: 'text',
    },
    {
      label: '家族構成 子供',
      required: false,
      type: 'family',
      parentContent: customersDataParent.childCount,
      childContent: customersDataChild.childCount,
      variableName: 'childCount',
      inputType: 'text',
    },
    {
      label: '子供の年齢1',
      required: false,
      type: 'family',
      parentContent: customersDataParent.childAge,
      childContent: customersDataChild.childAge,
      variableName: 'childAge',
      inputType: 'text',
    },
    {
      label: '子供の年齢2',
      required: false,
      type: 'family',
      parentContent: customersDataParent.otherChildAge,
      childContent: customersDataChild.otherChildAge,
      variableName: 'otherChildAge',
      inputType: 'text',
    },
    {
      label: '入居予定 人数',
      required: false,
      type: 'family',
      parentContent: customersDataParent.familyTotalCount,
      childContent: customersDataChild.familyTotalCount,
      variableName: 'familyTotalCount',
      inputType: 'text',
    },
    {
      label: '入居者の予定構成（誰が〕',
      required: false,
      type: 'family',
      parentContent: customersDataParent.familyWho,
      childContent: customersDataChild.familyWho,
      variableName: 'familyWho',
      inputType: 'text',
    },
    {
      label: '勤務先1',
      required: false,
      type: 'company',
      header: headers.company,
      parentContent: customersDataParent.company,
      childContent: customersDataChild.company,
      variableName: 'company',
      inputType: 'text',
    },
    {
      label: '勤務年数1',
      required: false,
      type: 'company',
      parentContent: customersDataParent.companyWorkingYear,
      childContent: customersDataChild.companyWorkingYear,
      variableName: 'companyWorkingYear',
      inputType: 'text',
    },
    {
      label: '勤務地住所1',
      required: false,
      type: 'company',
      parentContent: customersDataParent.companyAddress,
      childContent: customersDataChild.companyAddress,
      variableName: 'companyAddress',
      inputType: 'text',
    },
    {
      label: '勤務場所1',
      required: false,
      type: 'company',
      parentContent: customersDataParent.workingStyleCode,
      parentDisplayName: getTextValue(
        WORKING_STYLE_TYPE,
        customersDataParent.workingStyleCode,
      ),
      childContent: customersDataChild.workingStyleCode,
      childDisplayName: getTextValue(WORKING_STYLE_TYPE, customersDataChild.workingStyleCode),
      map: WORKING_STYLE_TYPE,
      variableName: 'workingStyleCode',
    },
    {
      label: '職業種1',
      required: false,
      type: 'company',
      parentContent: customersDataParent.jobType,
      childContent: customersDataChild.jobType,
      variableName: 'jobType',
      inputType: 'text',
    },
    {
      label: '勤務先2',
      required: false,
      type: 'company',
      parentContent: customersDataParent.subCompany,
      childContent: customersDataChild.subCompany,
      variableName: 'subCompany',
      inputType: 'text',
    },
    {
      label: '勤務年数2',
      required: false,
      type: 'company',
      parentContent: customersDataParent.subCompanyWorkingYear,
      childContent: customersDataChild.subCompanyWorkingYear,
      variableName: 'subCompanyWorkingYear',
      inputType: 'text',
    },
    {
      label: '勤務地住所2',
      required: false,
      type: 'company',
      parentContent: customersDataParent.subCompanyAddress,
      childContent: customersDataChild.subCompanyAddress,
      variableName: 'subCompanyAddress',
      inputType: 'text',
    },
    {
      label: '勤務場所2',
      required: false,
      type: 'company',
      parentContent: customersDataParent.subWorkingStyleCode,
      parentDisplayName: getTextValue(
        WORKING_STYLE_TYPE,
        customersDataParent.subWorkingStyleCode,
      ),
      childContent: customersDataChild.subWorkingStyleCode,
      childDisplayName: getTextValue(
        WORKING_STYLE_TYPE,
        customersDataChild.subWorkingStyleCode,
      ),
      map: WORKING_STYLE_TYPE,
      variableName: 'subWorkingStyleCode',
    },
    {
      label: '職業種2',
      required: false,
      type: 'company',
      parentContent: customersDataParent.subJobType,
      childContent: customersDataChild.subJobType,
      variableName: 'subJobType',
      inputType: 'text',
    },
    {
      label: '本人年収（万円）',
      required: false,
      type: 'budget',
      header: headers.budget,
      parentContent: customersDataParent.annualIncome,
      childContent: customersDataChild.annualIncome,
      variableName: 'annualIncome',
      inputType: 'text',
    },
    {
      label: '合算者年収（万円）',
      required: false,
      type: 'budget',
      parentContent: customersDataParent.subAnnualIncome,
      childContent: customersDataChild.subAnnualIncome,
      variableName: 'subAnnualIncome',
      inputType: 'text',
    },
    {
      label: '頭金（万円）',
      required: false,
      type: 'budget',
      parentContent: customersDataParent.deposit,
      childContent: customersDataChild.deposit,
      variableName: 'deposit',
      inputType: 'text',
    },
    {
      label: '援助（万円）',
      required: false,
      type: 'budget',
      parentContent: customersDataParent.assistAmount,
      childContent: customersDataChild.assistAmount,
      variableName: 'assistAmount',
      inputType: 'text',
    },
    {
      label: '月々の支払（万円）',
      required: false,
      type: 'budget',
      parentContent: customersDataParent.monthlyPayment,
      childContent: customersDataChild.monthlyPayment,
      variableName: 'monthlyPayment',
      inputType: 'text',
    },
    {
      label: 'ボーナス（万円）',
      required: false,
      type: 'budget',
      parentContent: customersDataParent.bonusPayment,
      childContent: customersDataChild.bonusPayment,
      variableName: 'bonusPayment',
      inputType: 'text',
    },
    {
      label: '希望地域',
      required: false,
      type: 'hope',
      header: headers.hope,
      parentContent: customersDataParent.wishArea,
      childContent: customersDataChild.wishArea,
      variableName: 'wishArea',
      inputType: 'modal',
    },
    {
      groupName: '希望路線1',
      inputGroup: [
        {
          label: '希望路線1',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.wishRailwayId1,
          childContent: customersDataChild.wishRailwayId1,
          parentDisplayName: customersDataParent.wishRailway1,
          childDisplayName: customersDataChild.wishRailway1,
          variableName: 'wishRailwayId1',
          inputType: 'modal',
        },
        {
          label: '希望駅1from',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.wishStationIdFrom1,
          childContent: customersDataChild.wishStationIdFrom1,
          parentDisplayName: customersDataParent.wishStationFrom1,
          childDisplayName: customersDataChild.wishStationFrom1,
          variableName: 'wishStationIdFrom1',
          inputType: 'modal',
        },
        {
          label: '希望駅1to',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.wishStationIdTo1,
          childContent: customersDataChild.wishStationIdTo1,
          parentDisplayName: customersDataParent.wishStationTo1,
          childDisplayName: customersDataChild.wishStationTo1,
          variableName: 'wishStationIdTo1',
          inputType: 'modal',
        },
      ],
    },
    {
      groupName: '希望路線2',
      inputGroup: [
        {
          label: '希望路線2',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.wishRailwayId2,
          childContent: customersDataChild.wishRailwayId2,
          parentDisplayName: customersDataParent.wishRailway2,
          childDisplayName: customersDataChild.wishRailway2,
          variableName: 'wishRailwayId2',
          inputType: 'modal',
        },
        {
          label: '希望駅2from',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.wishStationIdFrom2,
          childContent: customersDataChild.wishStationIdFrom2,
          parentDisplayName: customersDataParent.wishStationFrom2,
          childDisplayName: customersDataChild.wishStationFrom2,
          variableName: 'wishStationIdFrom2',
          inputType: 'modal',
        },
        {
          label: '希望駅2to',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.wishStationIdTo2,
          childContent: customersDataChild.wishStationIdTo2,
          parentDisplayName: customersDataParent.wishStationTo2,
          childDisplayName: customersDataChild.wishStationTo2,
          variableName: 'wishStationIdTo2',
          inputType: 'modal',
        },
      ],
    },
    {
      label: '希望学区1',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.wishSchoolDistrict1,
      childContent: customersDataChild.wishSchoolDistrict1,
      variableName: 'wishSchoolDistrict1',
      inputType: 'text',
    },
    {
      label: '希望学区2',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.wishSchoolDistrict2,
      childContent: customersDataChild.wishSchoolDistrict2,
      variableName: 'wishSchoolDistrict2',
      inputType: 'text',
    },
    {
      groupName: '戸建価格',
      inputGroup: [
        {
          label: '戸建価格from',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.detachedHouseBudgetMin,
          childContent: customersDataChild.detachedHouseBudgetMin,
          map: BUDGET_SELECT,
          variableName: 'detachedHouseBudgetMin',
          upperLimitVariable: 'detachedHouseBudgetMax',
        },
        {
          label: '戸建価格to',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.detachedHouseBudgetMax,
          childContent: customersDataChild.detachedHouseBudgetMax,
          map: BUDGET_SELECT,
          variableName: 'detachedHouseBudgetMax',
          lowerLimitVariable: 'detachedHouseBudgetMin',
        },
      ],
    },
    {
      groupName: '土地価格',
      inputGroup: [
        {
          label: '土地価格from',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.landBudgetMin,
          childContent: customersDataChild.landBudgetMin,
          map: BUDGET_SELECT,
          variableName: 'landBudgetMin',
          upperLimitVariable: 'landBudgetMax',
        },
        {
          label: '土地価格to',
          required: false,
          type: 'hope',
          parentContent: customersDataParent.landBudgetMax,
          childContent: customersDataChild.landBudgetMax,
          map: BUDGET_SELECT,
          variableName: 'landBudgetMax',
          lowerLimitVariable: 'landBudgetMin',
        },
      ],
    },
    {
      label: '希望間取り',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.floorPlanCode,
      parentDisplayName: getTextValue(FLOOR_PLAN, customersDataParent.floorPlanCode),
      childContent: customersDataChild.floorPlanCode,
      childDisplayName: getTextValue(FLOOR_PLAN, customersDataChild.floorPlanCode),
      map: FLOOR_PLAN,
      variableName: 'floorPlanCode',
    },
    {
      label: 'これまでに見た物件',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.checkedProductType,
      childContent: customersDataChild.checkedProductType,
      variableName: 'checkedProductType',
      inputType: 'text',
    },
    {
      label: 'これまでに見た物件数',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.checkedProductCount,
      childContent: customersDataChild.checkedProductCount,
      variableName: 'checkedProductCount',
      inputType: 'text',
    },
    {
      label: '検討のきっかけ',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.reason,
      childContent: customersDataChild.reason,
      variableName: 'reason',
      inputType: 'text',
    },
    {
      label: '入居希望時期',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.wishMoveInDay,
      childContent: customersDataChild.wishMoveInDay,
      variableName: 'wishMoveInDay',
      inputType: 'text',
    },
    {
      label: '検討（売却）',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.isThinkSale,
      parentDisplayName: getTextValue(IS_THINK_SALE, customersDataParent.isThinkSale),
      childContent: customersDataChild.isThinkSale,
      childDisplayName: getTextValue(IS_THINK_SALE, customersDataChild.isThinkSale),
      map: IS_THINK_SALE,
      variableName: 'isThinkSale',
    },
    {
      label: '検討（マンション）',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.isThinkApartment,
      parentDisplayName: getTextValue(
        IS_WISH_MANSION,
        customersDataParent.isThinkApartment,
      ),
      childContent: customersDataChild.isThinkApartment,
      childDisplayName: getTextValue(IS_WISH_MANSION, customersDataChild.isThinkApartment),
      map: IS_WISH_MANSION,
      variableName: 'isThinkApartment',
    },
    {
      label: '検討（一棟物投資）',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.isThinkWholeHouseInvestment,
      parentDisplayName: getTextValue(
        IS_THINK_WHOLE_HOUSE_INVESTMENT,
        customersDataParent.isThinkWholeHouseInvestment,
      ),
      childContent: customersDataChild.isThinkWholeHouseInvestment,
      childDisplayName: getTextValue(
        IS_THINK_WHOLE_HOUSE_INVESTMENT,
        customersDataChild.isThinkWholeHouseInvestment,
      ),
      map: IS_THINK_DIVISION_INVESTMENT,
      variableName: 'isThinkWholeHouseInvestment',
    },
    {
      label: '検討（区分投資）',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.isThinkDivisionInvestment,
      parentDisplayName: getTextValue(
        IS_THINK_DIVISION_INVESTMENT,
        customersDataParent.isThinkDivisionInvestment,
      ),
      childContent: customersDataChild.isThinkDivisionInvestment,
      childDisplayName: getTextValue(
        IS_THINK_DIVISION_INVESTMENT,
        customersDataChild.isThinkDivisionInvestment,
      ),
      map: IS_THINK_DIVISION_INVESTMENT,
      variableName: 'isThinkDivisionInvestment',
    },
    {
      label: '検討（建替）',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.isThinkRebuilding,
      parentDisplayName: getTextValue(
        IS_THINK_REBUILDING,
        customersDataParent.isThinkRebuilding,
      ),
      childContent: customersDataChild.isThinkRebuilding,
      childDisplayName: getTextValue(
        IS_THINK_REBUILDING,
        customersDataChild.isThinkRebuilding,
      ),
      map: IS_THINK_REBUILDING,
      variableName: 'isThinkRebuilding',
    },
    {
      label: '重視するポイント',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.importantPoint,
      childContent: customersDataChild.importantPoint,
      variableName: 'importantPoint',
      inputType: 'text',
    },
    {
      label: '購入目的',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.purchasePurpose,
      childContent: customersDataChild.purchasePurpose,
      variableName: 'purchasePurpose',
      inputType: 'text',
    },
    {
      label: '概要メモ',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.summary,
      childContent: customersDataChild.summary,
      variableName: 'summary',
      inputType: 'text',
    },
    {
      label: 'メモ',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.memo,
      childContent: customersDataChild.memo,
      variableName: 'memo',
      inputType: 'textarea',
    },
    {
      label: '駐車場希望',
      required: false,
      type: 'hope',
      parentContent: customersDataParent.isCarPark,
      parentDisplayName: getTextValue(IS_INVESTMENT1, customersDataParent.isCarPark),
      childContent: customersDataChild.isCarPark,
      childDisplayName: getTextValue(IS_INVESTMENT1, customersDataChild.isCarPark),
      map: IS_INVESTMENT1,
      variableName: 'isCarPark',
    },
    {
      label: '種類',
      required: false,
      type: 'situation',
      header: headers.situation,
      parentContent: getCodeValue(CURRENT_SITUATION_TYPE, customersDataParent.abodeNowBuildingType),
      parentDisplayName: customersDataParent.abodeNowBuildingType,
      childContent: getCodeValue(CURRENT_SITUATION_TYPE, customersDataChild.abodeNowBuildingType),
      childDisplayName: customersDataChild.abodeNowBuildingType,
      map: CURRENT_SITUATION_TYPE,
      variableName: 'abodeNowBuildingType',
    },
    {
      label: '間取り',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowFloorPlanCode,
      parentDisplayName: getTextValue(
        ABODE_NOW_FLOOR_PLAN,
        customersDataParent.abodeNowFloorPlanCode,
      ),
      childContent: customersDataChild.abodeNowFloorPlanCode,
      childDisplayName: getTextValue(
        ABODE_NOW_FLOOR_PLAN,
        customersDataChild.abodeNowFloorPlanCode,
      ),
      map: ABODE_NOW_FLOOR_PLAN,
      variableName: 'abodeNowFloorPlanCode',
    },
    {
      label: '面積（㎡）',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowLivingSpace,
      childContent: customersDataChild.abodeNowLivingSpace,
      variableName: 'abodeNowLivingSpace',
      inputType: 'text',
    },
    {
      label: '当時の価格（万円）',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowPriceAtTime,
      childContent: customersDataChild.abodeNowPriceAtTime,
      variableName: 'abodeNowPriceAtTime',
      inputType: 'text',
    },
    {
      label: '居住期間（年）',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowResidencePeriod,
      childContent: customersDataChild.abodeNowResidencePeriod,
      variableName: 'abodeNowResidencePeriod',
      inputType: 'text',
    },
    {
      label: '家賃（万円）',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowRent,
      childContent: customersDataChild.abodeNowRent,
      variableName: 'abodeNowRent',
      inputType: 'text',
    },
    {
      label: '希望売却価格（万円）',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowSalePrice,
      childContent: customersDataChild.abodeNowSalePrice,
      variableName: 'abodeNowSalePrice',
      inputType: 'text',
    },
    {
      label: '買替有無',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowReplacementCode,
      parentDisplayName: getTextValue(
        IS_REPLACEMENT,
        customersDataParent.abodeNowReplacementCode,
      ),
      childContent: customersDataChild.abodeNowReplacementCode,
      childDisplayName: getTextValue(
        IS_REPLACEMENT,
        customersDataChild.abodeNowReplacementCode,
      ),
      map: IS_REPLACEMENT,
      variableName: 'abodeNowReplacementCode',
    },
    {
      label: '購入年月',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowPurchaseAt,
      childContent: customersDataChild.abodeNowPurchaseAt,
      variableName: 'abodeNowPurchaseAt',
    },
    {
      label: '借入銀行',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowBankName,
      childContent: customersDataChild.abodeNowBankName,
      variableName: 'abodeNowBankName',
      inputType: 'text',
    },
    {
      label: '借入額（万円）',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowBorrowingAmount,
      childContent: customersDataChild.abodeNowBorrowingAmount,
      variableName: 'abodeNowBorrowingAmount',
      inputType: 'text',
    },
    {
      label: '購入残年数（年）',
      required: false,
      type: 'situation',
      parentContent: customersDataParent.abodeNowCurrentMaturity,
      childContent: customersDataChild.abodeNowCurrentMaturity,
      variableName: 'abodeNowCurrentMaturity',
      inputType: 'text',
    },
    {
      label: '反響基準日',
      required: true,
      type: 'response',
      header: headers.response,
      parentContent: customersDataParent.effectAt,
      childContent: customersDataChild.effectAt,
      variableName: 'effectAt',
    },
    {
      label: '源泉獲得者',
      required: false,
      type: 'response',
      parentContent: customersDataParent.acquisitionId,
      childContent: customersDataChild.acquisitionId,
      // 下記機能のためacquisitionMemo追加
      // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-2942#comment-163859391
      parentDisplayName: customersDataParent.acquisitionName || customersDataParent.acquisitionMemo,
      childDisplayName: customersDataChild.acquisitionName || customersDataChild.acquisitionMemo,
      variableName: 'acquisitionId',
      childAcquisitionCode: customersDataChild.acquisitionCode,
    },
    {
      label: '獲得者種',
      required: false,
      type: 'response',
      parentContent: customersDataParent.acquisitionCode,
      childContent: customersDataChild.acquisitionCode,
      parentDisplayName:
        customersDataParent.acquisitionCode,
      childDisplayName:
        customersDataChild.acquisitionCode,
      variableName: 'acquisitionCode',
    },
    {
      label: '担当者',
      required: true,
      type: 'response',
      parentContent:
        customersDataParent.userId || `d${customersDataParent.divisionId}`,
      childContent: customersDataChild.userId || `d${customersDataChild.divisionId}`,
      parentDisplayName:
        customersDataParent.userLastName + customersDataParent.userFirstName
        || customersDataParent.divisionSubName,
      childDisplayName:
        customersDataChild.userLastName + customersDataChild.userFirstName
        || customersDataChild.divisionSubName,
      variableName: 'userId',
    },
    {
      groupName: '反響媒体',
      inputGroup: [
        {
          label: '反響媒体(大)',
          required: true,
          type: 'response',
          parentContent:
            customersDataParent.largeResponseTypeId === 0
              ? null
              : customersDataParent.largeResponseTypeId,
          childContent:
            customersDataChild.largeResponseTypeId === 0
              ? null
              : customersDataChild.largeResponseTypeId,
          parentDisplayName: customersDataParent.largeResponseTypeName,
          childDisplayName: customersDataChild.largeResponseTypeName,
          variableName: 'largeResponseTypeId',
          inputType: 'modal',
        },
        {
          label: '反響媒体(中)',
          required: false,
          type: 'response',
          parentContent:
            customersDataParent.mediumResponseTypeId === 0
              ? null
              : customersDataParent.mediumResponseTypeId,
          childContent:
            customersDataChild.mediumResponseTypeId === 0
              ? null
              : customersDataChild.mediumResponseTypeId,
          parentDisplayName: customersDataParent.mediumResponseTypeName,
          childDisplayName: customersDataChild.mediumResponseTypeName,
          variableName: 'mediumResponseTypeId',
          inputType: 'modal',
        },
        {
          label: '反響媒体(小)',
          required: false,
          type: 'response',
          parentContent:
            customersDataParent.smallResponseTypeId === 0
              ? null
              : customersDataParent.smallResponseTypeId,
          childContent:
            customersDataChild.smallResponseTypeId === 0
              ? null
              : customersDataChild.smallResponseTypeId,
          parentDisplayName: customersDataParent.smallResponseTypeName,
          childDisplayName: customersDataChild.smallResponseTypeName,
          variableName: 'smallResponseTypeId',
          inputType: 'modal',
        },
        {
          label: '反響媒体(細)',
          required: false,
          type: 'response',
          parentContent:
            customersDataParent.fineResponseTypeId === 0
              ? null
              : customersDataParent.fineResponseTypeId,
          childContent:
            customersDataChild.fineResponseTypeId === 0
              ? null
              : customersDataChild.fineResponseTypeId,
          parentDisplayName: customersDataParent.fineResponseTypeName,
          childDisplayName: customersDataChild.fineResponseTypeName,
          variableName: 'fineResponseTypeId',
          inputType: 'modal',
        },
      ],
    },
    {
      label: '非反響フラグ',
      required: false,
      type: 'response',
      parentContent: customersDataParent.isNoCustomer,
      childContent: customersDataChild.isNoCustomer,
      variableName: 'isNoCustomer',
      inputType: 'checkbox',
    },
    {
      label: '当社を知ったきっかけ',
      required: false,
      type: 'response',
      parentContent: customersDataParent.reasonKnowOh,
      childContent: customersDataChild.reasonKnowOh,
      variableName: 'reasonKnowOh',
      inputType: 'text',
    },
    {
      label: '状態',
      required: false,
      type: 'inHouse',
      header: headers.inHouse,
      parentContent: customersDataParent.customerStatusCode,
      parentDisplayName: getTextValue(
        CUSTOMER_STATUS_CODE,
        customersDataParent.customerStatusCode,
      ),
      childContent: customersDataChild.customerStatusCode,
      childDisplayName: getTextValue(
        CUSTOMER_STATUS_CODE,
        customersDataChild.customerStatusCode,
      ),
      map: customeCustomerStatusCode,
      variableName: 'customerStatusCode',
    },
    {
      label: '電話ステータス',
      required: false,
      type: 'inHouse',
      parentContent: customersDataParent.telStatusCode,
      parentDisplayName: getTextValue(
        TEL_STATUS_CODE,
        customersDataParent.telStatusCode,
      ),
      childContent: customersDataChild.telStatusCode,
      childDisplayName: getTextValue(
        TEL_STATUS_CODE,
        customersDataChild.telStatusCode,
      ),
      map: TEL_STATUS_CODE,
      variableName: 'telStatusCode',
    },
    {
      label: 'メール許可',
      required: false,
      type: 'inHouse',
      parentContent: customersDataParent.emailStatusCode,
      parentDisplayName: getTextValue(
        EMAIL_STATUS_CODE,
        customersDataParent.emailStatusCode,
      ),
      childContent: customersDataChild.emailStatusCode,
      childDisplayName: getTextValue(
        EMAIL_STATUS_CODE,
        customersDataChild.emailStatusCode,
      ),
      map: EMAIL_STATUS_CODE,
      variableName: 'emailStatusCode',
    },
    {
      label: 'ランク（課員）',
      required: false,
      type: 'inHouse',
      parentContent: customersDataParent.userLevel,
      parentDisplayName: getTextValue(
        USER_LEVEL,
        customersDataParent.userLevel,
      ),
      childContent: customersDataChild.userLevel,
      childDisplayName: getTextValue(USER_LEVEL, customersDataChild.userLevel),
      map: USER_LEVEL,
      variableName: 'userLevel',
    },
    {
      label: '上長メモ',
      required: false,
      type: 'inHouse',
      parentContent: customersDataParent.leaderMemo,
      childContent: customersDataChild.leaderMemo,
      variableName: 'leaderMemo',
      inputType: 'textarea',
    },
    // 0 対象外、1 対象
    {
      label: '顧客紹介対象',
      required: false,
      type: 'inHouse',
      parentContent: customersDataParent.isCustomerIntroduction,
      childContent: customersDataChild.isCustomerIntroduction,
      variableName: 'isCustomerIntroduction',
      inputType: 'checkbox',
    },
    {
      label: 'ドライブURL1',
      required: false,
      type: 'googleDrive',
      header: headers.googledrive,
      parentContent: customersDataParent.driveUrl1,
      childContent: customersDataChild.driveUrl1,
      variableName: 'driveUrl1',
      inputType: 'text',
    },
    {
      label: 'ドライブURL2',
      required: false,
      type: 'googleDrive',
      parentContent: customersDataParent.driveUrl2,
      childContent: customersDataChild.driveUrl2,
      variableName: 'driveUrl2',
      inputType: 'text',
    },
    {
      label: 'ドライブURL3',
      required: false,
      type: 'googleDrive',
      parentContent: customersDataParent.driveUrl3,
      childContent: customersDataChild.driveUrl3,
      variableName: 'driveUrl3',
      inputType: 'text',
    },
  ];
};

export default convertFormData;
