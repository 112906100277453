/* eslint-disable max-len */
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles(() => ({
  wrap: {
    marginTop: '6px',
    display: 'flex',
    width: '100%',
    [commonTheme.breakpoints.up('md')]: {
      borderBottom: '1px #8c8c8c solid',
      borderRadius: 0,
      paddingBottom: '10px',
    },
  },
  title: {
    whiteSpace: 'nowrap',
    textAlign: 'left',
    borderRadius: 0,
    paddingLeft: 8,
  },
  txt: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.2,
    letterSpacing: 0,
    marginLeft: 16,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  wrapSp: {
    whiteSpace: 'nowrap',
    display: 'flex',
    lineHeight: 1.6,
    fontWeight: '700',
    fontSize: '13px',
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
    padding: '16px',
    flexShrink: 0,
    borderBottom: '1px solid #F3F3F3',
    [commonTheme.breakpoints.down('sm')]: {
      textTransform: 'none',
    },
  },
  txtSp: {
    marginLeft: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'right',
      fontSize: 13,
      fontWeight: 400,
      lineHeight: 1.6,
    },
  },
}));

function SearchLabel(props) {
  const { values } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  return (
    isSp ? (
      <Grid className={classes.wrapSp}>
        <Typography>
          {values.label}
        </Typography>
        <Typography className={classes.txtSp}>
          {values.state}
        </Typography>
      </Grid>
    ) : (
      <Grid className={classes.wrap}>
        <Typography className={`${baseClasses.title6} ${classes.title}`}>
          {values.label}
        </Typography>
        <Typography className={classes.txt}>
          {values.state}
        </Typography>
      </Grid>
    )
  );
}

export default SearchLabel;
