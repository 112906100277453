import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import CommonHeaderComponent from '../../../components/common/commonHeader/commonHeaderComponent';
import CalendarMaster from '../../../components/common/calendarMaster';
import FileStrage from '../../../components/fileStrage';
import BtnAndDialog from '../../../components/eleCommon/btnAnddraggableDialog';
import MainmenuHeader from '../../../components/common/commonHeader/parts/mainmenuHeader';
import commonStyles from '../../../components/styles';
import MailAfterCallSetting from '../../../components/mail/afterCallSetting';
import DashBoardPage from '../../../components/pages/dashBoardPage';
import usePermissions from '../../../../src/hooks/usePermissions';

import { getEnvVariable } from '../../../commonFunction/getEnv';

import { URL_MAPPER } from '../../../constants/urls';

const useStyles = makeStyles((theme) => ({
  child: {
    color: theme.palette.primaryColor,
    marginLeft: '16px',
    marginBottom: '8px',
    width: 'calc(100% - 16px)',
    borderRadius: '20px 0 0 20px',
    justifyContent: 'flex-start',
    paddingLeft: '16px',
    background: '#f3f3f3',
    '&:hover': {
      background: `${theme.palette.primaryColor}29`,
    },
  },
}));

export default function CommonHeaderContainer() {
  const classes = useStyles();
  const common = commonStyles();
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  const responseHeader = useSelector((state) => state.responseHeader);
  const { checkUrlPermission, checkComponentPermission } = usePermissions();
  const menulist = useMemo(
    () => [
      {
        key: 'sfa',
        txt: '顧客情報(SFA)',
        children: [
          {
            type: 'comp',
            txt: 'ダッシュボード',
            component: (
              <BtnAndDialog
                key="calendarmaster"
                styles={`${classes.child} ${common.strong}`}
                label="ダッシュボード"
              >
                <DashBoardPage />
              </BtnAndDialog>
            ),
          },
          {
            type: 'link',
            txt: '顧客メイン',
            href: URL_MAPPER.customer,
          },
          {
            type: 'link',
            txt: '顧客一括管理',
            href: URL_MAPPER.change,
          },
          {
            type: 'link',
            txt: '行動予定',
            href: URL_MAPPER.schedule,
          },
          {
            type: 'link',
            txt: '見込み案件管理',
            href: URL_MAPPER.customer_list,
          },
          {
            type: 'link',
            txt: '指示一覧',
            href: URL_MAPPER.direct,
          },
          {
            type: 'heading',
            txt: 'メール関連',
          },
          {
            type: 'link',
            txt: '一斉メール',
            href: URL_MAPPER.mail,
            isDisabled: !checkUrlPermission(URL_MAPPER.mail),
          },
          {
            type: 'link',
            txt: '未送信メール',
            href: URL_MAPPER.unsend_mail,
          },
          {
            type: 'comp',
            txt: '不通時メール',
            component: (<MailAfterCallSetting user={{}} styles={`${classes.child} ${common.strong}`} />),
          },
          {
            type: 'link',
            txt: 'ライブラリ管理',
            href: URL_MAPPER.library,
          },
          {
            type: 'comp',
            txt: 'ファイルストレージ',
            component: (<FileStrage key="menufilestrage" id="menufilestrage" styles={`${classes.child} ${common.strong}`} isDisabled={!checkComponentPermission('menufilestrage')} />),
          },
        ],
      },
      {
        key: 'totalling',
        txt: '集計',
        children: [
          // {
          //   type: 'externalLink',
          //   txt: '案内数一覧',
          //   href: 'https://datastudio.google.com/u/0/reporting/957d1b75-9102-4765-be50-d454ef3a47f1/page/wGRgC',
          // },
          {
            type: 'externalLink',
            txt: '行動量',
            href: 'https://datastudio.google.com/reporting/13LMeqCB1oxp9f_sV1omNT2OEgU2E5JRi/page/ciCt',
          },
        ],
      },
      {
        key: 'crm',
        type: 'link',
        txt: '反響処理 (CRM)',
        href: URL_MAPPER.crm,
        isDisabled: !checkUrlPermission(URL_MAPPER.crm),
      },
      {
        key: 'master',
        txt: 'マスタ',
        children: [
          {
            type: 'link',
            txt: '組織・社員マスタ',
            href: URL_MAPPER.user,
            isDisabled: !checkUrlPermission(URL_MAPPER.user),
          },
          {
            type: 'link',
            txt: '目標設定',
            href: URL_MAPPER.target,
            isDisabled: !checkUrlPermission(URL_MAPPER.target),
          },
          {
            type: 'comp',
            txt: '営業カレンダーマスタ',
            component: (
              <BtnAndDialog
                key="calendarmaster"
                styles={`${classes.child} ${common.strong}`}
                label="営業カレンダーマスタ"
                maxWidth={570}
                isDisabled={!checkComponentPermission('calendarmaster')}
              >
                <CalendarMaster />
              </BtnAndDialog>
            ),
          },
          {
            type: 'link',
            txt: '反響媒体マスタ',
            href: URL_MAPPER.response_type,
            isDisabled: !checkUrlPermission(URL_MAPPER.response_type),
          },
          {
            type: 'link',
            txt: '注意語句一覧マスタ',
            href: URL_MAPPER.prohibited,
            isDisabled: !checkUrlPermission(URL_MAPPER.prohibited),
          },
          {
            type: 'heading',
            txt: '反響自動処理設定',
            href: URL_MAPPER.simulator,
          },
          {
            type: 'link',
            txt: '自動処理モード設定',
            href: URL_MAPPER.auto_regist,
            isDisabled: !checkUrlPermission(URL_MAPPER.auto_regist),
          },
          {
            type: 'link',
            txt: '店舗グループ設定',
            href: URL_MAPPER.store_group,
            isDisabled: !checkUrlPermission(URL_MAPPER.store_group),
          },
          // 一時的に非表示 https://openhouse.backlog.jp/view/OH_KDSFA-1085
          // {
          //   type: 'link',
          //   txt: '組合せ設定',
          //   href: URL_MAPPER.combination,
          //   isDisabled: !checkUrlPermission(URL_MAPPER.combination),
          // },
          {
            type: 'link',
            txt: '配点設定',
            href: URL_MAPPER.point,
            isDisabled: !checkUrlPermission(URL_MAPPER.point),
          },
          {
            type: 'link',
            txt: 'シミュレーター',
            href: URL_MAPPER.simulator,
            isDisabled: !checkUrlPermission(URL_MAPPER.simulator),
          },
          {
            type: 'heading',
            txt: '課編成設定',
          },
          {
            type: 'link',
            txt: '課編成日付設定',
            href: URL_MAPPER.date_time,
            isDisabled: !checkUrlPermission(URL_MAPPER.date_time),
          },
          {
            type: 'link',
            txt: '店舗間顧客移動設定',
            href: URL_MAPPER.customer_move,
            isDisabled: !checkUrlPermission(URL_MAPPER.customer_move),
          },
          {
            type: 'link',
            txt: '組織情報一括更新',
            href: URL_MAPPER.user_move,
            isDisabled: !checkUrlPermission(URL_MAPPER.user_move),
          },
          {
            type: 'link',
            txt: '組織情報一括更新_履歴',
            href: URL_MAPPER.user_move_history,
            isDisabled: !checkUrlPermission(URL_MAPPER.user_move_history),
          },
          {
            type: 'link',
            txt: '営業自店舗課編成',
            href: URL_MAPPER.store_user_move,
            isDisabled: !checkUrlPermission(URL_MAPPER.store_user_move),
          },
        ],
      },
      {
        key: 'links',
        txt: 'リンク',
        children: [
          {
            type: 'externalLink',
            txt: 'レインズ',
            href: 'https://system.reins.jp/',
          },
          {
            type: 'externalLink',
            txt: 'レジスタ',
            href: `${getEnvVariable('REGISTER_BASE_URL')}/bukken/search/search`,
          },
          {
            type: 'externalLink',
            txt: '自動チラシ作成',
            href: getEnvVariable('AUTO_PAMPHLET_FORM'),
          },
        ],
      },
      {
        key: 'help',
        txt: 'ヘルプ',
        children: [
          {
            type: 'externalLink',
            txt: '結合依頼',
            href: 'https://docs.google.com/forms/d/e/1FAIpQLSdfgKEDdWDzo9hijQ4ecZgRtu7xv_8XGNBolsp5rKE0HXAFaw/viewform',
          },
          {
            type: 'externalLink',
            txt: 'マニュアル',
            href: 'https://sites.google.com/openhouse-group.com/new-sfa-manual/home',
          },
          {
            type: 'externalLink',
            txt: '物件広告104番',
            href: 'https://sites.google.com/openhouse-group.com/bukken-kokoku2',
          },
        ],
      },
    ],
    [responseHeader],
  );
  if (isSp) {
    /*
     * only-sp
     */
    return <CommonHeaderComponent />;
  } else {
    /*
     * only-pc
     */
    // メニューリスト
    // TODO: ユーザー情報を取得
    const user = [
      {
        shozoku: '戸建',
        name: responseHeader?.userName,
        logout: 'https://',
      },
    ];
    return (
      <MainmenuHeader menulist={menulist} user={user} />
    );
  }
}
