import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomerApi from '../../apis/customer/customerApi';
import { getCustomerApi } from '../../store/customer/customerSlice';
// SPで使用
import divisionsGetResponseTreeApi from '../../apis/divisions/divisionsGetResponseTreeApi';
import { getDivisionsResponseTreeApi } from
'../../store/divisions/divisionsGetResponseTreeSlice';
// get customer015
import customerGetBrowsingHistoryApi from '../../apis/customer/customerGetBrowsingHistoryApi';
import { customerGetBrowsingHistory } from '../../store/customer/customerGetBrowsingHistorySlice';
import actCreateCustomHistoryApi from '../../apis/act/actCreateCustomHistoryApi';

import actCreateHistoryApi from '../../apis/act/actCreateHistoryApi';
import actPutHistoryApi from '../../apis/act/actPutHistoryApi';
import actDeleteHistoryApi from '../../apis/act/actDeleteHistoryApi';
import { actCreateHistory } from '../../store/act/actCreateHistorySlice';
import { actPutHistory } from '../../store/act/actPutHistorySlice';
import { actDeleteHistory } from '../../store/act/actDeleteHistorySlice';
import customerDeleteShareApi from '../../apis/customer/customerDeleteShareApi';
import { useQuery, useRerender } from '../../hooks';
import {
  getLocalStorage,
  setLocalStorage,
  getNowDate,
  debounce,
  downloadZip,
} from '../../commonFunction';
import { TREE_TYPECODE, TREE_UNITCODE, TREE_DIVISION_ID } from '../../constants/localStorage';
import { bulkUpdateGotDateTime } from '../../store/customer/gotDataTimeSlice';
import sendmailAttachmentsDownloadApi from '../../apis/sendmail/sendmailAttachmentsDownloadApi';

const pageCacheKey = 'customerMainHeaderPage';

const CustomerMainContext = createContext({});

const CustomerMainProvider = React.memo((props) => {
  const {
    children,
  } = props;

  const isFirstRender = useRef(true);
  const [selectUser, setSelectUser] = useState({ userId: '', userName: '' });
  const [selectDivision, setSelectDivision] = useState({ divId: '', divisionName: '' });
  const [selectUniqueId, setSelectUniqueId] = useState(undefined);
  const customerPrf = useSelector((state) => state.customer.customer);

  // 連打防止用フラグ
  const [isDisabled, setIsDisabled] = useState(false);

  const { rerender, rerenderKey } = useRerender();

  // 対応履歴の検索条件を初期化
  const { rerender: rerenderSearch, rerenderKey: rerenderKeySearch } = useRerender();

  const queryCustomerId = useQuery('customerId');
  const isSP = useSelector((state) => state.deviceTypeSlice.isSp);

  // 簡易検索 検索条件
  const searchConditions = useSelector((state) => state.searchItems.searchParams);

  // ログイン中のユーザーの情報を取得
  const responseHeader = useSelector((state) => state.responseHeader);
  // ログイン情報
  const initLoginData = useMemo(() => {
    return {
      userId: responseHeader.userId,
      userName: responseHeader.userName,
    };
  }, [responseHeader]);

  const defaultHeader = {
    typeCode: getLocalStorage(TREE_TYPECODE, 0), // constants/index.js DIVISION_CODE
    unitCode: getLocalStorage(TREE_UNITCODE, 2), // constants/index.js UNIT_CODE
    divisionId: getLocalStorage(TREE_DIVISION_ID, responseHeader?.mainDivision?.id),
  };

  // 対応履歴
  const [actionHistory, setActionHistory] = useState({});
  const [actionHistorySearch, setActionHistorySearch] = useState({
    offset: 0,
    limit: 1000,
  });

  const dispatch = useDispatch();

  // 反響ツリー選択顧客 TODO:初期値はログインユーザーに紐づく顧客を設定
  const [selectCustomer, setSelectCustomer] = useState(
    (queryCustomerId && queryCustomerId !== 'null')
      ? [{ customerId: queryCustomerId }] : [],
  );

  // 表示ページ
  const [page, setPage] = useState(0);

  // 表示件数 Localstorageのデータを取得して設定 なければ20
  const rowsPerPageInitial = getLocalStorage(pageCacheKey, 20);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageInitial);

  // 初期化 Customer
  const initialCustomerFunction = useCallback(() => {
    dispatch(getCustomerApi(
      {
        customers: [
          {
            customerId: '',
          },
        ],
      },
    ));
  }, []);

  // GET Customer
  // 二重で叩かれる問題があるが、根本解決ではないが、処理の実行を間引く(最後にキックされたパラメータで実行される)
  // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-4354
  const DEBOUNCE_TIME = 100; // ms
  const getCustomerFunction = useCallback(debounce(async (
    customerId,
    pageVal,
    rowsPerPageVal,
    isUrlParameter = 0,
  ) => {
    if (isSP) {
      // update: 2023/03/07 SPの時は処理しない
      // src/containers/common/customer/customerInfo/customerInfoContainer.jsのcustomer001を使用するため
      // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-3937
      return;
    }
    if (isDisabled) return;
    setIsDisabled(true);
    if (customerId.length) {
      let ids = [];
      if (customerId.length < ((pageVal + 1) * rowsPerPageVal)) {
        ids = customerId.slice(pageVal * rowsPerPageVal);
      } else {
        ids = customerId.slice(pageVal * rowsPerPageVal, pageVal * rowsPerPageVal + rowsPerPageVal);
      }
      const historiesSearchConditons = {
        offset: 0,
        limit: 20,
      };
      const customerIds = ids.map((item) => item.customerId);
      const [customersRes, historiesRes] = await Promise.all([
        // 顧客取得
        CustomerApi(
          customerIds,
          isSP ? 1 : 0,
          searchConditions.isWebHistoryExist || 0,
          0,
          isUrlParameter,
        ),
        // 対応履歴取得
        actCreateCustomHistoryApi({
          customerIds,
          ...historiesSearchConditons,
        }),
      ]);

      // リセット検索条件
      setActionHistorySearch(historiesSearchConditons);

      // 更新用に日付をセット
      const getDataAt = getNowDate();
      customersRes.data.getDataAt = getDataAt;

      if (customersRes.data.customers.length > 0) {
        // 取得した顧客情報を表示するまでのローディングを実行
        dispatch(getCustomerApi(customersRes.data));
        dispatch(bulkUpdateGotDateTime({
          customerIds,
          gotDataTime: getDataAt,
        }));
      } else {
        dispatch(getCustomerApi(
          {
            customers: [
              {
                customerId: '',
              },
            ],
          },
        ));
      }

      const histories = { ...actionHistory };
      historiesRes.forEach((item) => {
        histories[item.id] = item;
      });
      setActionHistory(histories);
      if (!isFirstRender.current) {
        rerender();
      } else {
        isFirstRender.current = false;
      }
    } else {
      dispatch(getCustomerApi(
        {
          customers: [
            {
              customerId: '',
            },
          ],
        },
      ));
    }
    setIsDisabled(false);
  }, DEBOUNCE_TIME), [searchConditions, isDisabled]);

  // GET Customer 個人単位
  const [customerDetail, setCustomerDetail] = useState(null);
  const [customerMail, setCustomerMail] = useState(null);
  const getCustomerFunctionSingle = useCallback(async (customerId, detailFlg) => {
    const [customersRes] = await Promise.all([
      // 顧客取得
      CustomerApi(customerId, isSP ? 1 : 0, searchConditions.isWebHistoryExist || 0),
    ]);
    if (detailFlg) {
      setCustomerDetail(customersRes.data.customers[0]);
    } else {
      setCustomerMail(customersRes.data.customers[0]);
    }
  }, []);

  // GET Customer SP
  const getCustomerFunctionSp = useCallback(async (customerId, isUrlParameter) => {
    if (customerId.length) {
      const id = customerId;
      await CustomerApi(id, isSP ? 1 : 0, 0, 0, isUrlParameter)
        .then((res) => {
          // 更新用に日付をセット
          res.data.getDataAt = getNowDate();
          dispatch(getCustomerApi(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      dispatch(getCustomerApi(
        {
          customers: [],
        },
      ));
    }
  }, []);

  // 顧客のメールアドレスをstoreから取得
  // https://openhouse.backlog.jp/view/OH_KDSFA-681
  const getCustomerMailFunction = useCallback((customerId) => {
    const targetCustomer = customerPrf?.customers.find(c => {
      return c.customerId === customerId;
    });
    setCustomerMail(targetCustomer);
  }, [customerPrf]);

  // 1ページに表示する件数を変更時
  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    // Localstorageに保存
    setLocalStorage(pageCacheKey, +event.target.value);
    // 顧客情報を再取得
    getCustomerFunction(selectCustomer, 0, event.target.value);
  }, [selectCustomer]);

  // ページ更新
  const updatePage = useCallback((initialPage) => {
    if (isDisabled) return;
    setIsDisabled(true);
    let isUrlParameter;
    if (queryCustomerId
      && selectCustomer.length === 1
      && selectCustomer[0].customerId === queryCustomerId
    ) {
      isUrlParameter = 1;
    } else {
      isUrlParameter = 0;
    }
    if (initialPage === 0) {
      setPage(0);
      getCustomerFunction(selectCustomer, initialPage, rowsPerPage, isUrlParameter);
    } else {
      getCustomerFunction(selectCustomer, page, rowsPerPage, isUrlParameter);
    }
    setIsDisabled(false);
  }, [selectCustomer, page, rowsPerPage, isDisabled]);

  // 次ページ、前ページ押下時
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // 顧客情報を再取得
    getCustomerFunction(selectCustomer, newPage, rowsPerPage);
    const wrapper = document.getElementById('MainItem__Grid');
    wrapper.scrollTo(0, 0);
  };

  // 【SP】get: 顧客閲覧履歴 customer015
  // TO CONFIRM: 500エラー https://openhouse.backlog.jp/view/SFA_ASIAQUEST-263
  const getBrowsingHistoryFunction = async (params) => {
    if (params && !params.limit) console.log('customer015: 取得上限数パラメータが設定されていません');
    if (params && (!Number.isInteger(params.offset) && !(params.offset))) console.log('customer015: 取得開始位置パラメータが設定されていません');
    const path = `?limit=${params.limit}`
      + `&offset=${params && params.offset}`;
    await customerGetBrowsingHistoryApi(path || '')
      .then((res) => {
        dispatch(customerGetBrowsingHistory(res.data));
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  const [searchHeader, setSearchHeader] = useState({});

  // POST[/division/customertree]
  // eslint-disable-next-line max-len
  const getCustomerTreeResponseFunction = useCallback(async (params, isNotDispatch = false, isCustomers = false) => {
    let paramObj = { ...params };
    // typeCodeの初期値は0のため、1→0に変更
    if (!params.typeCode && params.typeCode !== 0) paramObj.typeCode = 0;
    if (!params.unitCode && params.unitCode !== 0) paramObj.unitCode = 2;
    if (!params.divisionId && params.divisionId !== 0) {
      paramObj.divisionId = getLocalStorage(TREE_DIVISION_ID, responseHeader?.mainDivision?.id);
    }
    let result;
    if (isCustomers) {
      paramObj = { customerIds: [isCustomers] };
    }
    await divisionsGetResponseTreeApi(paramObj)
      .then(async (res) => {
        if (!isNotDispatch) await dispatch(getDivisionsResponseTreeApi(res.data));
        result = res.data;
      })
      .catch((err) => {
        console.log(err);
        result = false;
      });
    return result;
  }, []);

  // 対応履歴取得
  const getActionHistoryList = async (id, searchObj) => {
    const params = {
      ...searchObj,
      // idはnullの場合、一覧の顧客のIDをパラメーターにする
      customerIds: id ? [id] : customerPrf.customers.map((item) => item.customerId),
    };
    await actCreateCustomHistoryApi(params)
      .then(async (arr) => {
        const data = {};
        arr.forEach((item) => {
          data[item.id] = item;
        });
        const newState = structuredClone(actionHistory);
        params.customerIds.forEach((cid) => {
          newState[cid] = data[cid] || { total: 0, histories: [] };
        });
        setActionHistory(newState);
      })
      .catch(console.error);
  };

  // 対応履歴情報登録
  const createHistory = async (cid, parmObj) => {
    await actCreateHistoryApi(parmObj)
      .then(async (res) => {
        await dispatch(actCreateHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        getActionHistoryList(cid, actionHistorySearch);
        // 対応履歴の検索条件を初期化
        rerenderSearch();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 対応履歴情報更新
  const updateHistory = async (cid, id, parmObj) => {
    await actPutHistoryApi(id, parmObj)
      .then(async (res) => {
        await dispatch(actPutHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        getActionHistoryList(cid, actionHistorySearch);
        // 対応履歴の検索条件を初期化
        rerenderSearch();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 対応履歴情報削除
  const deleteHistory = async (cid, id) => {
    await actDeleteHistoryApi(id)
      .then(async (res) => {
        await dispatch(actDeleteHistory(res.data));
        // 【act003】対応履歴取得（顧客ID指定）
        getActionHistoryList(cid, actionHistorySearch);
        // 対応履歴の検索条件を初期化
        rerenderSearch();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // DELETE : /customers/share/{customerId}
  const deleteCustomerShare = async (params) => {
    await customerDeleteShareApi(params)
      .then(async () => {
        // 反響ツリーを再取得
        getCustomerTreeResponseFunction({ ...searchConditions, ...defaultHeader });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 添付ファイルダウンロード
  const downloadAttachment = async (actionId) => {
    await sendmailAttachmentsDownloadApi(actionId)
      .then((f) => downloadZip(f))
      .catch((err) => console.error(err));
  };

  // API用の関数をまとめたオブジェクト
  const functions = {
    getCustomerFunction,
    getCustomerFunctionSingle,
    getCustomerFunctionSp,
    getCustomerTreeResponseFunction, // 反響ツリー 情報取得
    getBrowsingHistoryFunction, // 顧客閲覧履歴 customer015 取得関数
    getActionHistoryList, // 対応履歴取得
    actionHistorySearch, // 対応履歴取得する条件
    setActionHistorySearch, // 対応履歴取得する条件を更新する関数
    actionHistory,
    createHistory, // 対応履歴登録
    updateHistory, // 対応履歴更新
    deleteHistory, // 対応履歴削除
    updatePage,
    handleChangePage,
    handleChangeRowsPerPage,
    initialCustomerFunction,
    deleteCustomerShare,
    downloadAttachment,
    getCustomerMailFunction,
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    searchHeader,
    setSearchHeader,
    functions,
    selectCustomer,
    setSelectCustomer,
    selectUser,
    setSelectUser,
    selectDivision,
    setSelectDivision,
    selectUniqueId,
    setSelectUniqueId,
    queryCustomerId,
    initLoginData,
    page,
    rowsPerPage,
    rerenderKey,
    customerDetail,
    setCustomerDetail,
    customerMail,
    setCustomerMail,
    updatePage,
    defaultHeader,
    rerenderKeySearch,
    rerenderSearch,
    isDisabled,
    setIsDisabled,
  };

  return (
    <CustomerMainContext.Provider value={value}>
      {children}
    </CustomerMainContext.Provider>
  );
});

const useCustomerMain = () => {
  return useContext(CustomerMainContext);
};

export { CustomerMainProvider, useCustomerMain };
