import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  makeStyles,
  // Tooltip,
} from '@material-ui/core';
import commonStyles from '../../../styles';
import { updateObjSearchItems, createInitialState } from '../../../../store/search/searchItemsSlice';
import { useKanniKensaku } from '../../../../containers/search/kanniKensaku/KanniKensakuContext';

const useStyles = makeStyles((theme) => ({
  checkBox: {
    marginRight: 2,
    marginLeft: 0,
    '& span': {
      fontSize: 13,
    },
  },
  checkBoxMain: {
    '&.Mui-checked': {
      color: theme.palette.primaryColor,
    },
  },
  flex3: {
    width: 'calc(33% - 4px)',
  },
  checkBoxWrap: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 4,
    marginBottom: 4,
  },
  box: {
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  },
  infoIcon: {
    color: '#D83420',
    fontSize: 20,
  },
  title: {
    marginBottom: 8,
  },
}));

function SortCheckBox2() {
  const {
    searchConditions,
    setSearchConditions,
  } = useKanniKensaku();

  const classes = useStyles();
  const common = commonStyles();
  const dispatch = useDispatch();
  // 更新用
  const [count, setCount] = useState(0);

  const sortCheckRankList = {
    title: 'ランク',
    key: 'userLevel',
    state: searchConditions.userLevel,
    menu: {
      5: '★5',
      4: '★4',
      3: '★3',
      2: '★2',
      1: '★1',
      0: '★なし',
    },
  };

  const sortCheckList = {
    title: '案内・接触（2022年10月以降）',
    data: [
      {
        key: 'searchType4',
        title: '案内済（直近1週間内）',
        state: searchConditions.searchType4,
      },
      {
        key: 'searchType5',
        title: '未接触',
        state: searchConditions.searchType5,
      },
      {
        key: 'isContactOrReceptionSearch',
        title: '接触済（返信含）',
        state: searchConditions.isContactOrReceptionSearch,
      },
    ],
  };

  // checkedの状態に応じて、userLevelの配列に値を追加、削除
  const handleChange1 = (event, num) => {
    const copyState = Object.assign([], sortCheckRankList.state);
    if (event.target.checked) {
      copyState.push(num);
      setSearchConditions({ ...searchConditions, userLevel: copyState });
    } else {
      const i = copyState.indexOf(num);
      copyState.splice(i, 1);
      setSearchConditions({ ...searchConditions, userLevel: copyState });
    }
    setCount(count + 1);
  };
  // checkedの状態に応じて、絞りこみ条件のフラグを立てる
  const handleChange2 = (event) => {
    let checkStatus = event.target.checked;
    if (checkStatus) {
      checkStatus = Number(checkStatus);
    } else {
      checkStatus = null;
    }

    if (!event.target?.name) return;
    setSearchConditions({ ...searchConditions, [event.target.name]: checkStatus });
    setCount(count + 1);
  };

  // ランク用のチェックボックス
  const createCheckBox1 = (data, obj) => (
    <Grid key={data} className={`${classes.checkBoxWrap} ${classes.flex3}`}>
      <FormControlLabel
        key={`checkbox${data}`}
        className={classes.checkBox}
        size="small"
        control={
          <Checkbox
            checked={obj.state.includes(Number(data))}
            onChange={(e) => {
              handleChange1(e, Number(data));
            }}
            name={obj.label}
            color="primary"
            value={obj.menu[data]}
            className={classes.checkBoxMain}
          />
        }
        label={obj.menu[Number(data)]}
      />
    </Grid>
  );
  // 案内・接触用のチェックボックス
  const createCheckBox2 = (data) => (
    <Grid key={data.key} className={classes.checkBoxWrap}>
      <FormControlLabel
        className={classes.checkBox}
        size="small"
        control={
          <Checkbox
            checked={Boolean(data.state)}
            onChange={handleChange2}
            name={data.key}
            color="primary"
            className={classes.checkBoxMain}
          />
        }
        label={data.title}
      />
    </Grid>
  );

  useEffect(() => {
    // FIXME: そもそもuseEffectではなく、クリック時の更新で良い気がするが、調査に時間かかるので後回し
    // グローバルステート(簡易検索条件)の更新
    // このコンポーネントが見込み案件管理画面のみの検索条件で、他画面と検索条件を共有しないのでローカルストレージに保存しない
    // https://openhouse.backlog.jp/view/SFA_ASIAQUEST-5108
    dispatch(updateObjSearchItems(searchConditions));
  }, [count]);

  useEffect(() => {
    // 他画面に遷移するときにここで変更した値を破棄する
    return () => {
      const initialState = createInitialState();
      dispatch(updateObjSearchItems({
        userLevel: initialState.userLevel,
        searchType4: initialState.searchType4,
        searchType5: initialState.searchType5,
        isContactOrReceptionSearch: initialState.isContactOrReceptionSearch,
      }));
    };
  }, []);

  return (
    <>
      <Grid className={classes.box}>
        <Typography className={`${common.title4} ${classes.title}`}>{sortCheckRankList.title}</Typography>
        <Grid container>
          {Object.keys(sortCheckRankList.menu).map((c) => createCheckBox1(c, sortCheckRankList))}
        </Grid>
      </Grid>
      <Grid className={classes.box}>
        <Typography className={`${common.title4} ${classes.title}`}>{sortCheckList.title}</Typography>
        <Grid container>
          {sortCheckList.data.map((data) => createCheckBox2(data))}
        </Grid>
      </Grid>
    </>
  );
}
export default SortCheckBox2;
